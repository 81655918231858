import { LinkButton } from '@/components/elements/forms/buttons';
import { BlogLayout } from '@/components/layouts/blog';
import { CategoryNav, FeaturedPost, GiftCardBanner, Heading, Hero, LatestPosts } from '@/components/modules/pages/blog';
import { url } from '@/helpers';
import useScrollToTop from '@/hooks/useScrollToTop';
import { _s } from '@/locale';
import { Helmet } from 'react-helmet';

const getSEO = () => {
  const title = _s('seo.blogBlogTitle');
  const description = _s('seo.blogBlogDescription');
  const pageUrl = `${url.getBaseUrl()}blogg`;
  const imageUrl = url.getBaseImageUrl();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={pageUrl} />

      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
    </Helmet>
  );
};

const BlogHome = () => {
  useScrollToTop();
  return (
    <BlogLayout>
      <Hero />
      <div className="container mb-32 mt-10 md:mt-20">
        <Heading />
        <CategoryNav />
        <FeaturedPost />
        <GiftCardBanner className="my-10 mb-8" />
        <LatestPosts />
        <LinkButton variant="primary" to="/blogg/list">
          {_s('blogPage.viewAllPosts')}
        </LinkButton>
      </div>
      {getSEO()}
    </BlogLayout>
  );
};

export default BlogHome;
