import LoadingDotsBlack from '@/components/animations/LoadingDotsBlack/LoadingDotsBlack';
import LoadingDotsBlue from '@/components/animations/LoadingDotsBlue/LoadingDotsBlue';
import LoadingDotsWhite from '@/components/animations/LoadingDotsWhite/LoadingDotsWhite';
import { classnames } from '@/helpers';
import { themed } from '@/helpers/theme';
import { ButtonHTMLAttributes, LegacyRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { ButtonSize, ButtonVariant } from '../types';
import css from './Button.module.scss';

export const BUTTON_PADDING = {
  sm: 'px-md py-sm',
  md: 'px-lg py-md',
  lg: 'px-xl py-lg',
} as const;

const base = (size: ButtonSize) =>
  classnames('inline-flex items-center justify-center', `rounded-sm rounded-xs gap-x-md text-${size}`);

const getVariant = (variant: ButtonVariant, disabled: boolean) => {
  const variants = {
    primary: classnames(
      themed('text-white bg-black-900', 'text-black-800 bg-sm_primary'),
      !disabled && themed('hover:bg-black-700', 'hover:text-black-800'),
      disabled && 'opacity-40',
    ),
    secondary: classnames(
      'text-black-900 bg-black-100',
      disabled
        ? themed('opacity-40', '!text-sm_primary !border-sm_primary-300')
        : themed('hover:bg-black-50', 'hover:bg-sm_primary hover:text-black-800'),
    ),
    tertiary: classnames(
      themed('text-white bg-brand hover:bg-brand-200', 'text-black-800'),
      'border-none',
      disabled && 'opacity-40',
    ),
    link: classnames('text-information-700 underline', disabled && 'opacity-40'),
  };

  return variants[variant];
};

const btnClasses = (
  variant: ButtonVariant,
  size: ButtonSize,
  block: boolean,
  disabled: boolean,
  className?: string,
  inline?: boolean,
) =>
  twMerge(
    base(size),
    getVariant(variant, disabled),
    block && '!flex !w-full sm:!w-full',
    inline ? '!outline-none' : BUTTON_PADDING[size],
    className,
  );

type Icon = JSX.Element;

type GenericButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  block?: boolean;
  label?: string;
  leftIcon?: Icon;
  rightIcon?: Icon;
  iconNoFilter?: boolean;
  disabled?: boolean;
  loading?: boolean;
  inline?: boolean;
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & GenericButtonProps;

const IconWrapper = ({ icon, size }: { icon: Icon; size: ButtonSize }) => {
  return <span className={classnames(css.icon, css[size])}>{icon}</span>;
};

const getLoadingIcon = (variant: ButtonVariant, size: ButtonSize) => {
  const loadingIconStyle = {
    sm: { top: '-5px', width: '48px', height: '19px' },
    md: { top: '-3px', width: '48px', height: '22px' },
    lg: { top: '-2px', width: '48px', height: '24px' },
  };
  const loadingIcons = {
    primary: <LoadingDotsWhite loop style={loadingIconStyle[size]} />,
    secondary: <LoadingDotsBlack loop style={loadingIconStyle[size]} />,
    tertiary: <LoadingDotsWhite loop style={loadingIconStyle[size]} />,
    link: <LoadingDotsBlue loop style={loadingIconStyle[size]} />,
  };

  return loadingIcons[variant];
};

const Button = forwardRef(
  (
    {
      variant = 'primary',
      label,
      leftIcon,
      rightIcon,
      size = 'sm',
      block,
      className = '',
      children,
      loading,
      inline,
      iconNoFilter = false,
      ...props
    }: ButtonProps,
    ref: LegacyRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        className={`${css.button} ${css[variant]} ${css[size]} ${
          iconNoFilter ? '' : css['invertIconColor']
        } ${btnClasses(variant, size, block, props.disabled, className, inline)}`}
        {...props}>
        {leftIcon && <IconWrapper size={size} icon={leftIcon} />}
        {loading ? getLoadingIcon(variant, size) : label ?? children}
        {rightIcon && <IconWrapper size={size} icon={rightIcon} />}
      </button>
    );
  },
);
export const LinkButton = ({
  variant = 'link',
  label,
  leftIcon,
  rightIcon,
  size = 'sm',
  block,
  className = '',
  children,
  loading,
  iconNoFilter = false,
  ...props
}: ButtonProps & LinkProps) =>
  (props.to && typeof props.to === 'string' && props.to.startsWith('/')) || props.to?.pathname ? (
    <Link
      className={`${css.button} ${css[variant]} ${css[size]} ${iconNoFilter ? '' : css['invertIconColor']} ${btnClasses(
        variant,
        size,
        block,
        props.disabled,
        className,
      )}`}
      {...props}>
      {leftIcon && <IconWrapper size={size} icon={leftIcon} />}
      {loading ? getLoadingIcon(variant, size) : label ?? children}
      {rightIcon && <IconWrapper size={size} icon={rightIcon} />}
    </Link>
  ) : (
    <a
      href={props.to ?? '#'}
      {...props}
      className={`${css.button} ${css[variant]} ${css[size]} ${btnClasses(
        variant,
        size,
        block,
        props.disabled,
        className,
      )}`}
      {...props}>
      {leftIcon && <IconWrapper size={size} icon={leftIcon} />}
      {loading ? getLoadingIcon(variant, size) : label ?? children}
      {rightIcon && <IconWrapper size={size} icon={rightIcon} />}
    </a>
  );

export default Button;
