import { searchConstants } from '../constants';

let initialState = { orderBy: 1 };
let param = {};

export function search(state = initialState, action) {
  switch (action.type) {
    case searchConstants.SET_PARAMS:
      param = action.parameter;
      let values = {};
      for (const p in param) {
        if (param[p] && param[p].id) {
          values[p] = param[p].id;
        } else if (param[p] && param[p].selected) {
          const multiple = [];
          param[p].selected.forEach((item) => {
            multiple.push(item.id ? item.id : item);
          });

          values[p] = multiple.join(',');
        } else {
          values[p] = param[p];
        }
      }

      const shouldUpdate = values.shouldUpdate ?? true;

      return { ...state, ...action.parameter, ...values, shouldUpdate };
    case searchConstants.REMOVE_PARAMS:
      const search = { ...state };
      const toRemove = action.parameter;
      let shouldResetPage = false;
      // TODO: create some logic to know when a page reset is needed;
      if (toRemove === 'page') {
        shouldResetPage = true;
      }

      if (Array.isArray(toRemove)) {
        toRemove.forEach((item) => {
          delete search[item];
        });
      } else {
        delete search[toRemove];
      }

      if (search['bounds']) {
        delete search['bounds'];
      }

      if (shouldResetPage) {
        delete search['page'];
      }

      return { ...search };
    case searchConstants.RESET: {
      return Object.assign({}, { position: state.position || '', location: state.location || '' });
    }
    case searchConstants.START_LOCATION_LOADER:
      return {
        ...state,
        locationLoader: true,
        locationError: false,
      };
    case searchConstants.STOP_LOCATION_LOADER:
      return {
        ...state,
        locationLoader: false,
      };
    case searchConstants.LOCATION_ERROR:
      return {
        ...state,
        locationError: true,
      };
    case searchConstants.STORE_LIST:
      const { extend, extendPage, page, offset, extendSearchIteration } = action.params;

      return {
        ...state,
        places: action.params.places || [],
        allSearchItems: action.params.allSearchItems || [],
        topSearch: action.params.topSearch || [],
        results: (action.params.hits && action.params.hits.value) || 0,
        didInvalidate: false,
        fetching: false,
        showInjectionNotice: action.params.showInjectionNotice || false,
        page,
        extend,
        extendPage,
        offset,
        extendSearchIteration,
        extendSearchDisabled: !!action.params.extendSearchDisabled,
        dynamicSearchExtension: !!action.params.dynamicSearchExtension,
        dynamicSearchExtensionOffset: action.params.dynamicSearchExtensionOffset || 0,
      };
    default:
      return state;
  }
}
