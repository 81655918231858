import colors from '@/colors';
import Label from '@/components/elements/Label/Label';
import { ChevronIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { isMobile, scrollAccordionContentInView, startOfDay, trackMpEvent } from '@/helpers';
import { themed } from '@/helpers/theme';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { getSvgPath } from 'figma-squircle';
import { Fragment, useEffect, useRef, useState } from 'react';
import Bundle, { PlaceBundle } from './Bundle';

type Place = {
  id: number;
  bookingBundles?: PlaceBundle[];
};
type BundlesProps = {
  place: Place;
  isOpen?: boolean;
  date?: number;
  source?: string;
};

const baseTranslationKey = 'components.modules.salon.Bundles';

const Bundles = ({ place, isOpen, date, source }: BundlesProps) => {
  const [expanded, setExpanded] = useState(!!(isOpen ?? true));
  const [showNotice, setShowNotice] = useState(false);
  const { isMobileView } = useMobileView();
  const collapseClass = expanded ? 'block' : 'hidden';

  if (!place?.bookingBundles?.length) {
    return null;
  }

  if (!date) {
    date = startOfDay();
  }

  const handleCollapse = (e) => {
    const newExpanded = !expanded;
    if (expanded) {
      trackMpEvent('bundles_category_expanded', {
        company_id: place.id,
        screen_name: source ? source : 'company_details',
      });
      setExpanded(newExpanded);
      scrollAccordionContentInView(e.target);
    } else {
      setExpanded(newExpanded);
    }
  };

  const bundles = place.bookingBundles.map((item) => (
    <Fragment key={item.id}>
      <Bundle bundle={item} place={place} />
    </Fragment>
  ));

  const handleOnMouseEnter = () => {
    if (localStorage.getItem('hideBundleNoticeBanner') === 'true') {
      return;
    }
    setShowNotice(true);
  };

  const handleNoticeCloseClick = () => {
    localStorage.setItem('hideBundleNoticeBanner', 'true');
    setShowNotice(false);
  };

  return (
    <div className="relative">
      {showNotice && <NoticePopover onClose={handleNoticeCloseClick} />}
      <div
        className={`item  ${expanded && !isMobile() ? 'mb-4' : ''}`}
        {...(!isMobileView && { onMouseEnter: handleOnMouseEnter })}>
        <div
          onClick={handleCollapse}
          className={`mb-[2px] flex cursor-pointer items-center justify-between p-3 ${themed('bg-black-50', '')}`}>
          <h3 className="font-semibold">{_s('buyBundles.bundles')}</h3>
          <span className="ml-sm relative flex flex-1">
            <button
              aria-label={_s(`${baseTranslationKey}.showNotice.ariaLabel`)}
              aria-expanded={showNotice}
              aria-controls="popover"
              onClick={(e) => {
                e.stopPropagation();
                if (!showNotice) {
                  trackMpEvent('bundles_news_label_clicked', {});
                }
                setShowNotice((prev) => !prev);
              }}>
              <Label label={_s('Novelty!')} className="bg-warning-500 text-black-900" />
            </button>
          </span>
          <span
            className={`flex items-center justify-center rounded-full p-2 ${
              expanded ? 'bg-[#ADB4C4]' : themed('bg-primary', 'bg-sm_primary')
            }`}>
            <ChevronIcon className={`h-[8px] w-[8px] text-white ${expanded ? 'rotate-180' : ''}`} />
          </span>
        </div>

        <div className={'accordion-content ' + collapseClass} role="tabpanel">
          <ul>{bundles}</ul>
        </div>
      </div>
    </div>
  );
};

const NoticePopover = ({ onClose }) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (!popoverRef.current) return;
    const { width, height } = popoverRef.current.getBoundingClientRect();
    const path = getSvgPath({ width, height, cornerRadius: 18, cornerSmoothing: 1 });
    setStyle({ backgroundColor: colors.black[900], width, height, clipPath: `path('${path}')` });
  }, []);

  return (
    <div className="inset-lg absolute -top-[256px]">
      <div
        ref={popoverRef}
        className="bg-black-900 w-[320px] drop-shadow-lg"
        style={style}
        id="popover"
        role="dialog"
        aria-modal="true"
        aria-labelledby="popover-title"
        aria-describedby="popover-content">
        <div className="p-lg space-y-md">
          <div className="flex items-start justify-between">
            <span className="max-w-[200px] text-xl font-bold text-white" id="popover-title">
              {_s(`${baseTranslationKey}.NoticePopover.title`)}
            </span>
            <button onClick={onClose} aria-label={_s('close')}>
              <Icon variant="close-alt" color="white" />
            </button>
          </div>
          <p className="text-black-300" id="popover-content">
            {_s(`${baseTranslationKey}.NoticePopover.description`)}
          </p>
        </div>
      </div>
      <div className="relative">
        <div className="w-lg h-lg bg-black-900 absolute -top-[9px] left-[90px] rotate-45"></div>
      </div>
    </div>
  );
};

export default Bundles;
