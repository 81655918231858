import { Card, CardHeader } from '@/components/elements/cards';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import KlarnaPaymentMethodCategories from '@/components/modules/klarna/KlarnaPaymentMethodCategories';
import StepNavBar from '@/components/modules/pages/giftCards/StepNavBar';
import { GiftCardsBuyStandard, GiftCardsBuyWellness } from '@/components/modules/pages/giftCards/buy';
import {
  InfoColumnStandardCard,
  InfoColumnWellnessCard,
  ShippingSummaryCard,
} from '@/components/modules/pages/giftCards/buy/cards';
import {
  GIFTCARD_DEFAULT_VALUES,
  GIFTCARD_DEFAULT_VALUE_SELECTED,
  MIN_AMT_DIGITAL,
  MIN_AMT_SHIPPING,
  ShippingMethod,
  WELLNESS_DEFAULT_VALUES,
  WELLNESS_DEFAULT_VALUE_SELECTED,
} from '@/constants/giftcardConstants';
import { getWellnessCardExpiryDateFromToday, trackMpEvent, trackPage, url } from '@/helpers';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import useMobileView from '@/hooks/useMobileView';
import { __, _s } from '@/locale';
import { giftCardServices } from '@/services';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import gfCss from '@/styles/modules/giftcards.module.scss';
import * as Sentry from '@sentry/react';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import css from './GiftCardsBuy.module.scss';
/**
 *
 * @param {ShippingMethod} shippingMethod
 * @param {number} selectedValue
 * @param {boolean} isConfirmPage
 * @param {boolean} isFromHitta
 * @param {boolean} isWellness
 * @param {Function} createOrder
 */
const createOrderOnDataUpdate = ({
  shippingMethod,
  value,
  isConfirmPage,
  isFromHitta,
  isWellness,
  createKlarnaOrder,
  discount,
  ssn,
}) => {
  if (shippingMethod === ShippingMethod.DIGITAL && value >= MIN_AMT_DIGITAL && !isFromHitta) {
    createKlarnaOrder();
  }
};

/**
 *
 * @param {boolean} isConfirmPage
 * @param {boolean} showPaymentScreen
 * @param {Function} setShowPaymentScreen
 * @param {Function} setRenderingKlarna
 * @param {ShippingMethod} shippingMethod
 * @param {boolean} isWellness
 * @param {History} history
 */
const redirectIfInvalidPath = (
  isConfirmPage,
  showPaymentScreen,
  setShowPaymentScreen,
  setRenderingKlarna,
  shippingMethod,
  isWellness,
  history,
) => {
  window.scrollTo(0, 0);
  if (showPaymentScreen && isConfirmPage) return;

  if (isConfirmPage) {
    history.push('/giftcards/buy' + (isWellness ? '?ugctype=wellness' : ''));
    return;
  }
  setShowPaymentScreen(false);
  if (shippingMethod === ShippingMethod.SHIPPING) setRenderingKlarna(false);
};

const enforceMinAmountOnShipping = (
  shippingMethod,
  setRenderingKlarna,
  selectedValue,
  setValue,
  defaultSelectedValue,
) => {
  if (shippingMethod === ShippingMethod.DIGITAL) return;
  setRenderingKlarna(false);

  if (selectedValue < MIN_AMT_SHIPPING) {
    setValue(defaultSelectedValue);
  }
};

const trackScreenShown = (isWellness) => {
  trackPage();
  trackMpEvent('screen_shown', {
    screen_name: `${isWellness ? 'wellness_card' : 'gift_card'}_landing_page`,
  });
};

const GiftCardsBuy = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const { isMobileView } = useMobileView();
  const isConfirmPage = location.pathname?.includes('confirm') ?? false;
  const query = location.search ? queryString.parse(location.search) : {};
  const isWellness = query && query.ugctype && query.ugctype === 'wellness';
  const seoBaseString = isWellness ? 'buyGiftcardWellness' : 'buyGiftcardUniversal';
  const isFromHitta = query && giftCardServices.isObjectFromHittaIntegration(query);
  const hideHeader = query && query.utm_source && ['mp_android_app', 'mp_ios_app'].indexOf(query.utm_source) !== -1;

  let pricingValuesFromExperiment = useGetAmplitudeExperimentVariant()('ugc-prices')?.payload?.values;
  if (pricingValuesFromExperiment && Array.isArray(pricingValuesFromExperiment)) {
    pricingValuesFromExperiment = pricingValuesFromExperiment.filter(Number).map(Number);
  } else {
    pricingValuesFromExperiment = undefined;
  }

  const pricingValues = isWellness
    ? WELLNESS_DEFAULT_VALUES
    : pricingValuesFromExperiment?.length
    ? pricingValuesFromExperiment
    : GIFTCARD_DEFAULT_VALUES;
  const defaultSelectedValue = isWellness
    ? WELLNESS_DEFAULT_VALUE_SELECTED
    : pricingValuesFromExperiment?.length
    ? pricingValuesFromExperiment?.[1] ?? pricingValuesFromExperiment[0]
    : GIFTCARD_DEFAULT_VALUE_SELECTED;

  const [gc, setGC] = giftCardServices.useGiftCardData(
    defaultSelectedValue,
    MIN_AMT_DIGITAL,
    MIN_AMT_SHIPPING,
    isWellness,
    isFromHitta,
    isConfirmPage,
    css,
  );

  const [selectedServiceType, setSelectedServiceType] = useState({ taxRate: 0 });

  const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();

  const createKlarnaOrder = async () => {
    try {
      const updateResponse = await giftCardServices.createOrUpdateOrder({
        ...gc,
        ...(isWellness ? { serviceType: selectedServiceType } : {}),
      });

      initializeKlarnaSDK(updateResponse.clientToken ?? gc.clientToken);

      if (gc.isConfirmPage || gc.shippingMethod === ShippingMethod.DIGITAL)
        loadKlarnaWidget('#klarna-payment', updateResponse.clientToken ?? gc.clientToken);

      setGC.orderLines(updateResponse.orderLines);
      if (!gc.sessionID) {
        setGC.paymentMethodCategories(updateResponse.paymentMethodCategories);
        setGC.sessionID(updateResponse.sessionID);
        setGC.clientToken(updateResponse.clientToken);
      }
    } catch (error) {
      toast(({ closeToast }) => <Snackbar type="danger" label={_s('buyGiftcard.error')} onClose={closeToast} />);
      Sentry.captureException(error);
    }
  };

  const validationCompleted = (success) => {
    setGC.shouldValidate(false);
    setGC.showPaymentScreen(success);
    if (gc.value >= gc.minValue && success) {
      window.scrollTo(0, 0);
      createKlarnaOrder();
      history.push('/giftcards/buy/confirm' + (isWellness ? '?ugctype=wellness' : ''));
    }
  };

  const handleApplyDiscount = async (value, quantity) => {
    const response = await giftCardServices.checkDiscountCode(gc.code, value * quantity);
    // Giftcard applyed
    if (response.discount) {
      setGC.discount(response.discount / 100 || 0);
    }
  };

  const valueUpdate = (amount) => {
    if (amount < gc.minValue) return;
    if (gc.code) handleApplyDiscount(amount, gc.quantity);

    setGC.value(amount);
  };

  const quantityUpdate = (newQuantity) => {
    setGC.quantity(newQuantity);
    if (gc.code) handleApplyDiscount(gc.value, newQuantity);
  };

  const setDiscountCallback = (discount) => {
    setGC.discount(discount);
  };

  const setDiscountCode = (discountCode) => {
    setGC.code(discountCode);
  };

  const goBack = () => {
    history.push(path + (isWellness ? '?ugctype=wellness' : ''));
  };

  useEffect(() => {
    trackScreenShown(isWellness);
    setGC.renderingKlarna(false);
  }, [isWellness]);

  useEffect(() => valueUpdate(defaultSelectedValue), [defaultSelectedValue]);

  useEffect(
    giftCardServices.populateFromHittaObject(query, {
      setShippingMethod: setGC.shippingMethod(ShippingMethod.SHIPPING),
    }),
    [location],
  );

  useEffect(giftCardServices.populateFromHittaObject(query, setGC), [gc.shippingMethod]);

  // We create an order if shippingMethod and value are set & acceptable
  useEffect(
    () => createOrderOnDataUpdate({ ...gc, createKlarnaOrder }),
    [
      location,
      gc.value,
      gc.discount,
      gc.code,
      gc.shipping,
      gc.shippingMethod,
      gc.quantity,
      selectedServiceType,
      gc.ssn,
    ],
  );

  useEffect(
    () =>
      redirectIfInvalidPath(
        isConfirmPage,
        gc.showPaymentScreen,
        setGC.showPaymentScreen,
        setGC.renderingKlarna,
        gc.shippingMethod,
        isWellness,
        history,
      ),
    [location],
  );

  useEffect(
    () =>
      enforceMinAmountOnShipping(gc.shippingMethod, setGC.renderingKlarna, gc.value, setGC.value, defaultSelectedValue),
    [gc.shippingMethod],
  );

  const createOrder = async (res) => {
    try {
      const createRes = await giftCardServices.createGiftCardOrder(gc.sessionID, res.authorization_token);

      window.location = createRes.redirectURL;
    } catch (error) {
      if (error.message === 'Internal Server Error') {
        Sentry.captureMessage('Internal Server Error');
        return;
      }

      Sentry.captureException(error, {
        tags: {
          method: 'createOrder',
        },
      });
    }
  };
  const authorize = () => {
    authorizeKlarnaPurchase(gc.orderLines, 'pay_later', createOrder);
  };

  return (
    <PageViewLayout
      type={isMobileView ? 'subView' : 'mainView'}
      title={isWellness ? __('buyGiftcard.wellness.title') : __('buyGiftcard.universal.title')}
      {...(isMobileView && { back: true })}
      headerNavigationContainerClassName={hideHeader ? 'hidden' : ''}
      wrapperClass="bg-gradient">
      <div className="container-fixed mt-lg">
        <Switch exact path={path}>
          {isWellness ? (
            <>
              <div className="mb-4 flex flex-col items-center justify-center lg:hidden ">
                <img
                  src={'/images/product-wellnesscard.png'}
                  alt={''}
                  className="mb-lg max-w-full object-cover object-center"
                />
                <span className="text-secondary text-center text-2xl font-semibold">
                  {__('buyGiftcard.wellness.banner.title')}
                </span>
                <div className="text-md mb-3 mt-2 font-semibold">
                  {__('buyGiftcard.wellness.banner.temporary')}{' '}
                  <span className="text-danger bg-danger-50 inline-block p-1">31 Dec!</span>
                </div>
              </div>
              <div className="ml-3 items-center justify-start lg:hidden">
                <div className="flex-row py-1">
                  <img src={'/images/checked.svg'} width={15} height={15} alt={''} className="inline-block" />{' '}
                  <span className="ml-2 text-sm">{__('buyGiftcard.wellness.banner.bullet1')}</span>
                </div>
                <div className="flex-row py-1">
                  <img src={'/images/checked.svg'} width={15} height={15} alt={''} className="inline-block" />{' '}
                  <span className="ml-2 text-sm">{__('buyGiftcard.wellness.banner.bullet2')}</span>
                </div>
                <div className="flex-row  py-1">
                  <img src={'/images/checked.svg'} width={15} height={15} alt={''} className="inline-block" />{' '}
                  <span className="ml-2 text-sm">
                    {__('buyGiftcard.wellness.banner.bullet3', {
                      expire: getWellnessCardExpiryDateFromToday('DD MMMM, YYYY'),
                    })}
                  </span>
                </div>
              </div>
              <h1 className="h1-responsive sr-only mt-10 lg:not-sr-only">{__('buyGiftcard.wellness.title')}</h1>
              <div className="hidden items-center justify-start lg:flex">
                <span className="tag-warning">{__('Novelty')}</span>
                <span className="ml-3">{__('buyGiftcard.wellness.useWellness')}</span>
              </div>
            </>
          ) : (
            <>
              <div className="mb-4 flex flex-col items-center justify-center lg:hidden ">
                <img
                  src={'/images/product-giftcard.png'}
                  alt={''}
                  className=" mb-lg max-w-full object-cover object-center"
                />
                <span className="text-center text-2xl font-semibold">{__('giftCardHeader')}</span>
                <span className="text-center">{__('buyGiftcard.promo.title')}</span>
                <img src={'/images/payment-methods.png'} width={203} height={33} alt={''} className="block" />{' '}
              </div>
              <h1 className="h1-responsive sr-only lg:not-sr-only">{__('buyGiftcard.universal.title')}</h1>
              <div className="hidden items-center justify-start lg:flex">
                <span className={`tag-warning`}>{__('buyGiftcard.universal.tag')}</span>
                <span className="ml-3">{__('buyGiftcard.universal.tagText')}</span>
              </div>
            </>
          )}
        </Switch>
        <div className={gfCss.columns}>
          <div className={gfCss.checkoutColumn}>
            <Switch>
              <Route exact path={`${path}`}>
                {isWellness ? (
                  <GiftCardsBuyWellness
                    {...{
                      ...gc,
                      valueUpdate,
                      setQuantity: quantityUpdate,
                      setSSN: setGC.ssn,
                      authorize,
                      setSelectedServiceType,
                      pricingValues,
                    }}
                  />
                ) : (
                  <GiftCardsBuyStandard
                    {...{
                      ...gc,
                      valueUpdate,
                      setDiscount: setDiscountCallback,
                      setDiscountCode,
                      setQuantity: quantityUpdate,
                      setShippingMethod: setGC.shippingMethod,
                      setShipping: setGC.shipping,
                      setGreeting: setGC.greeting,
                      setShouldValidate: setGC.shouldValidate,
                      validationCompleted,
                      authorize,
                      pricingValues,
                    }}
                  />
                )}
              </Route>
              <Route path={`${path}/confirm`}>
                <StepNavBar
                  backAction={goBack}
                  backSteps={[1]}
                  steps={2}
                  currentStep={2}
                  title={__('buyGiftcard.completeOrder')}
                />
                <h2 className={`${css.mtOnMobile} mb-8 mt-6 text-lg font-semibold sm:text-2xl`}>
                  {__('buyGiftcard.completeOrder')}
                </h2>
                <ShippingSummaryCard {...{ ...gc, goBack }} />
                <Card className="">
                  <CardHeader>
                    {isWellness ? __('buyGiftcard.infoColumn.useWellness') : __('buyGiftcard.payment')}
                  </CardHeader>
                  {gc.paymentMethodCategories && (
                    <KlarnaPaymentMethodCategories {...{ paymentMethodCategories: gc.paymentMethodCategories }} />
                  )}

                  <div id="klarna-payment"></div>
                  {gc.sessionID && (
                    <Button size="md" block onClick={authorize}>
                      {__('buy')}
                    </Button>
                  )}
                </Card>
              </Route>
            </Switch>
          </div>
          <div className={gfCss.infoColumn}>
            <div className="sticky top-20" style={{ marginBottom: 24 }}>
              {isWellness ? (
                <InfoColumnWellnessCard showPaymentScreen={gc.showPaymentScreen} />
              ) : (
                <InfoColumnStandardCard />
              )}
              {!isWellness && !gc.showPaymentScreen && (
                <div className="text-md text-center">
                  {__('buyGiftcard.use')}{' '}
                  <LinkButton className="!p-0" size="md" to="/giftcards">
                    {__('buyGiftcard.useLink')}
                  </LinkButton>
                </div>
              )}
              {!isWellness && !gc.showPaymentScreen && (
                <Card
                  style={{ padding: 0, cursor: 'pointer' }}
                  onClick={() => {
                    history.push('/giftcards/buy?ugctype=wellness');
                  }}>
                  <CardHeader
                    className="rounded-lg rounded-b-none px-4 py-3 sm:px-6"
                    style={{ backgroundColor: '#E2ECF7' }}>
                    <span className="tag-warning mr-2">{__('NOVELTY!')} </span>
                    <span className="text-md text-information-700">
                      {__('buyGiftcard.infoColumn.useWellnessTitle')}
                    </span>
                  </CardHeader>
                  <div className="px-4 pb-4 sm:px-6 ">
                    {__('buyGiftcard.infoColumn.useWellnessDescription')}{' '}
                    <span className="text-information-700 !p-0 underline">
                      {__('buyGiftcard.infoColumn.useWellnessDescription.useLink')}
                    </span>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
      <SEO {...seoPropsFromBaseString(seoBaseString, url.getBaseUrl() + 'giftcards/buy')} />
    </PageViewLayout>
  );
};

export default GiftCardsBuy;
