import { secondsToHour } from '@/helpers';
import 'moment-timezone';
import * as moment from 'moment-timezone';
import { bookConstants } from '../constants';
import {
  addTimezone,
  capitalizeFirstLetter,
  formatPrice,
  getImagePreview,
  isEmpty,
  isEqual,
  isMobile,
  isOnlinePaymentRequired,
  isSistaminuten,
} from './';
import { config } from './../config';
import { __, _s } from './../locale';
import { server } from './serverRequests';

export function exportIdSlug(seourl) {
  seourl = decodeURI(seourl);
  const parts = seourl.split('-');

  const id = parts.pop();
  const slug = parts && parts.length ? parts.join('-') : '';

  return { id, slug };
}
export function getAddress(address) {
  if (isEmpty(address)) return '';

  let addressString = '';

  if (address.street) {
    addressString += address.street.trim().replace(/,$/, '');
  }

  if (address.zipcode) {
    addressString += ', ' + address.zipcode + (address.city ? ' ' + address.city : '');
  } else {
    if (address.city) {
      addressString += ', ' + address.city;
    }
  }

  return addressString;
}
export function putLessUsedEmployeeFirst(employees, employeesAllocation) {
  const availableEmployees = employees;
  let selectedEmployee = 0;
  const diff = availableEmployees.filter(function (x) {
    return employeesAllocation[x] === undefined;
  });

  if (diff.length > 0) {
    selectedEmployee = diff[0];
    employeesAllocation[diff[0]] = 1;
  } else {
    const intersect = availableEmployees.filter(function (x) {
      return employeesAllocation[x] !== undefined;
    });
    const min = { id: null, count: Number.MAX_VALUE };
    intersect.forEach(function (key) {
      if (employeesAllocation[key] < min.count) {
        min.count = employeesAllocation[key];
        min.id = key;
      }
    });

    selectedEmployee = min.id;
    employeesAllocation[min.id] = min.count + 1;
  }

  return [selectedEmployee].concat(employees).filter((v, i, a) => a.indexOf(v) === i);
}

export function getServiceDuration(service, priceTypeId, type) {
  if (isEmpty(service) || typeof service !== 'object' || !service.priceType) {
    return '';
  }

  if (service.about && service.about.settings && service.about.settings.hideDuration) {
    return '';
  }
  let duration = 0;

  if (!priceTypeId) {
    // Don't care about price type
    if (service.priceType.durationRange && service.priceType.durationRange !== '') {
      if (type === 2) {
        return 0;
      }
      const parts = service.priceType.durationRange.split('-');
      return parseInt(parts[0], 10) / 60 + ' - ' + parseInt(parts[1], 10) / 60 + ' ' + __('min');
    }

    duration = service.about && service.about.displayDuration ? service.about.displayDuration : service.duration;
    return type
      ? type === 2
        ? service.duration
        : duration / 60 + ' ' + __('min')
      : __('minutes', { count: duration / 60 });
  }

  // Filter on priceType
  if (service.priceType && service.priceType.displayDurations && service.priceType.displayDurations[priceTypeId]) {
    return type
      ? type === 2
        ? service.priceType.displayDurations[priceTypeId]
        : service.priceType.displayDurations[priceTypeId] / 60 + ' ' + __('min')
      : __('minutes', { count: service.priceType.displayDurations[priceTypeId] / 60 });
  }

  if (service.priceType && service.priceType.durations && service.priceType.durations[priceTypeId]) {
    return type
      ? type === 2
        ? service.priceType.durations[priceTypeId]
        : service.priceType.durations[priceTypeId] / 60 + ' ' + __('min')
      : __('minutes', { count: service.priceType.durations[priceTypeId] / 60 });
  }

  return type === 2 ? 0 : __('variableDuration');
}

export function getTotalDuration(services, durationId, withLabel) {
  const length = services.length || 0;
  if (!services || !length) {
    return '';
  }

  let total = 0;
  let duration = 0;
  for (let i = 0; i < length; i++) {
    const service = services[i].service ? services[i].service : services[i];
    if (isEmpty(service) || typeof service !== 'object' || !service.priceType) {
      return '';
    }

    if (service.about && service.about.settings && service.about.settings.hideDuration) {
      return withLabel ? __('variableDuration') : -1;
    }

    if (
      durationId &&
      service.priceType &&
      service.priceType.displayDurations &&
      durationId in service.priceType.displayDurations
    ) {
      duration = parseFloat(service.priceType.displayDurations[durationId]);
      if (duration === 0 || isNaN(duration)) {
        return withLabel ? __('variableDuration') : -1;
      } else {
        total = total + duration;
        if (i !== length - 1) {
          total = addMargins(total, service);
        }
      }
    } else if (
      durationId &&
      service.priceType &&
      service.priceType.durations &&
      durationId in service.priceType.durations
    ) {
      duration = parseFloat(service.priceType.durations[durationId]);
      if (duration === 0 || isNaN(duration)) {
        return withLabel ? __('variableDuration') : -1;
      } else {
        total = total + duration;
        if (i !== length - 1) {
          total = addMargins(total, service);
        }
      }
    } else {
      if (service.priceType && service.priceType.durationRange && service.priceType.durationRange.length > 0) {
        return withLabel ? (length === 1 ? service.priceType.durationRange : __('variableDuration')) : -1;
      } else {
        duration = service.about && service.about.displayDuration ? service.about.displayDuration : service.duration;
        if (duration && !isNaN(parseFloat(duration))) {
          total = total + parseFloat(duration);
          if (i !== length - 1) {
            total = addMargins(total, service);
          }
        } else {
          return withLabel ? __('variableDuration') : -1;
        }
      }
    }
  }

  return withLabel ? parseInt(total / 60, 10) + ' ' + __('min') : total;
}

function addMargins(total, service) {
  // Append the margins
  if (service.about && service.about.settings && service.about.settings.marginAfter) {
    total = total + parseFloat(service.about.settings.marginAfter);
  }

  return total;
}

export function getDirectionsUrl(address, gotoLocation, fromLocation) {
  let url = 'https://www.hitta.se/kartan?rc=';
  if (isMobile()) {
    url += gotoLocation;
  } else {
    url += (fromLocation ? fromLocation + '&rc=' : '') + gotoLocation;
  }

  url += '&rn=' + address + '&zoom=12&center=' + gotoLocation + '&ct=navigation_link&src=hitta.se';
  return url;
}

export function getShortAddress(address) {
  if (isEmpty(address)) return '';

  let addressString = '';

  if (address.street) {
    addressString += address.street.trim().replace(/,$/, '');
  }

  if (address.city) {
    addressString += ', ' + address.city;
  }

  return addressString;
}

export function performedServices(services, employees) {
  const ids = (services || []).map((service) => service.id);
  let employeeData = [];
  for (let i = 0; i < (employees || []).length; i++) {
    const intersection = ids.filter((value) => -1 !== employees[i].services.indexOf(value));
    if (isEqual(intersection, ids)) {
      employeeData = employeeData.concat(employees[i].services);
    }
  }
  return employeeData.filter((v, i, a) => a.indexOf(v) === i);
}

export function formatServicePrice(price, isPriceVariable = '', campaignService) {
  if (!campaignService) {
    return isPriceVariable + price + ' kr';
  }

  const offerPrice = getOfferPrice(price, campaignService);
  if (offerPrice.indexOf('-') === -1) {
    return parseInt(offerPrice, 10) === 0 ? __('freePrice') : isPriceVariable + offerPrice + ' kr';
  }
  return offerPrice.split('-').every((price) => parseInt(price, 10) === 0)
    ? __('freePrice')
    : isPriceVariable + offerPrice + ' kr';
}

export function getServiceRangePrice(prices, priceId, time) {
  const priceValues = [];
  const dynamicPriceKey = priceId ? getDynamicPriceKey(priceId, time?.dynamicPriceListIdKey) : undefined;

  Object.keys(prices).forEach((key) => {
    let keyToCheck = priceId && !dynamicPriceKey ? priceId : dynamicPriceKey;
    let deleted = false;
    if (!keyToCheck && time?.dynamicPriceListIdKey) {
      if (time.dynamicPriceListIdKey !== bookConstants.DYNAMIC_PRICE_KEY_DEFAULT) {
        keyToCheck = time.dynamicPriceListIdKey;
      } else {
        if (
          key.indexOf(bookConstants.DYNAMIC_PRICE_KEY_WEEKEND) !== -1 ||
          key.indexOf(bookConstants.DYNAMIC_PRICE_KEY_EVENING) !== -1
        ) {
          deleted = true;
        }
      }
    }

    if (keyToCheck ? key.indexOf(keyToCheck) !== -1 : !deleted) {
      priceValues.push(prices[key]);
    }
  });

  if (priceValues.some((price) => price === 0 || isNaN(price))) {
    return NaN;
  }

  const countPriceValues = priceValues.length;

  if (countPriceValues > 0) {
    const maxPrice = Math.max(...priceValues);
    if (countPriceValues > 1) {
      const minPrice = Math.min(...priceValues);
      if (maxPrice !== minPrice) {
        return minPrice + '-' + maxPrice;
      } else {
        return maxPrice;
      }
    } else {
      return maxPrice;
    }
  }

  return NaN;
}

export function getServicePrice(service, priceId, place, campaignService, time) {
  if (!(service && service.priceType && service.priceType.prices) || !(place && place.about)) {
    return '';
  }

  if (!showPrices(place, [service])) {
    return '';
  }

  const { about } = service;
  const { settings = {} } = about || {};

  // free service
  if (place.source === 2 && settings.isChargeable && settings.isFree) {
    return __('freePrice');
  }

  const hasDynamicPrice = hasDynamicPricing(service);
  const isPriceVariable = settings.showFrom ? __('from') + ' ' : '';
  const variableOrFree = settings.isChargeable ? __('variablePrice') : __('freePrice');

  if (priceId && priceId in service.priceType.prices) {
    if (!hasDynamicPrice || time?.dynamicPriceListIdKey === bookConstants.DYNAMIC_PRICE_KEY_DEFAULT) {
      const price = formatPrice(service.priceType.prices[priceId]);

      if (parseInt(price, 10) === 0) {
        return variableOrFree;
      } else {
        return formatServicePrice(price, isPriceVariable, campaignService);
      }
    } else {
      // the time chosen has weekend/evening price
      const dynamicPriceKey = getDynamicPriceKey(priceId, time?.dynamicPriceListIdKey);
      if (dynamicPriceKey && dynamicPriceKey in service.priceType.prices) {
        const price = formatPrice(service.priceType.prices[dynamicPriceKey]);

        if (parseInt(price, 10) === 0) {
          return variableOrFree;
        } else {
          return formatServicePrice(price, isPriceVariable, campaignService);
        }
      }
    }
  }

  // is a range probably
  if (Object.keys(service.priceType.prices).length > 0) {
    const serviceRange = getServiceRangePrice(service.priceType.prices, priceId, hasDynamicPrice ? time : undefined);
    const priceCheck = parseInt(serviceRange, 10);
    if (isNaN(priceCheck)) {
      return variableOrFree;
    }

    const unformattedRange = sumUp(serviceRange, 0);
    // price formatted
    const range = ('' + unformattedRange).split('-').map((item) => formatPrice(item));

    // if it's a from price do not return a range
    if (!!isPriceVariable) {
      return formatServicePrice(range[0], isPriceVariable, campaignService);
    }

    return formatServicePrice(range.join('-'), '', campaignService);
  } else {
    // check service.price
    const price = formatPrice(service.price);

    if (parseInt(price, 10) === 0) {
      return variableOrFree;
    } else {
      return formatServicePrice(price, isPriceVariable, campaignService);
    }
  }
}

export function getOfflinePaymentFee(employee, capacity = 1) {
  let fee = parseInt((employee.about && employee.about.settings && employee.about.settings.offlinePaymentFee) || 0, 10);
  if (!isNaN(fee)) {
    return parseInt(capacity, 10) ? parseInt(capacity, 10) * (fee / 100) : fee / 100;
  }

  return 0;
}

export function getTrackingPrices(
  place,
  services,
  priceId,
  capacity = 1,
  serviceCampaigns = {},
  offlineFee = 0,
  time = undefined,
) {
  if (!services || services.length === 0) {
    return {};
  }

  let total = 0;
  let items = [];
  const quantity = capacity && parseInt(capacity, 10) > 1 ? parseInt(capacity, 10) : 1;

  for (let i = 0; i < services.length; i++) {
    const service = services[i].service ? services[i].service : services[i];
    if (isEmpty(service) || typeof service !== 'object' || !service.priceType || !service.priceType.prices) {
      continue;
    }
    const item = {
      id: service.id,
      name: service.name,
      quantity: quantity,
      currency: 'SEK',
    };

    const price = parseFloat(
      '' +
        getServicePrice(service, priceId, place, serviceCampaigns[service.id], time)
          .replace(' kr', '')
          .replace(/\s/g, ''),
    );
    if (!(price === 0 || isNaN(price))) {
      item.price = price;
    }

    if (!item.price) {
      item.price = 0;
    }

    total = sumUp(total, item.price);
    items.push(item);
  }

  if (total && offlineFee) {
    offlineFee = parseFloat(offlineFee);
    if (!isNaN(offlineFee) && offlineFee > 0) {
      items.push({
        id: 'OfflineFee',
        name: 'Offline Fee',
        quantity: quantity,
        currency: 'SEK',
        price: offlineFee,
      });
      total = sumUp(total, offlineFee);
    }
  }

  total = quantity * total;

  return {
    total,
    items,
  };
}

export function shouldShowFrom(services, place) {
  services = services && services.length ? services : [services];

  for (let i = 0; i < services.length; i++) {
    const service = services[i] && services[i].service ? services[i].service : services[i];
    if (service && service.about && service.about.settings && service.about.settings.showFrom) {
      return __('from') + ' ';
    }
  }

  return false;
}

export function getOfferPrice(price, campaignService) {
  if (!price) return '';

  const offer = [];
  const range = ('' + price).replace(' kr', '').replace(/\s/g, '').split('-', 2);

  if (campaignService.discountPrice) {
    offer[0] = parseFloat(range[0]) - parseFloat(campaignService.discountPrice);
    if (range[1]) {
      offer[1] = parseFloat(range[1]) - parseFloat(campaignService.discountPrice);
    }
  } else {
    if (campaignService.discountPercent) {
      offer[0] = parseFloat(range[0]) * (1 - parseFloat(campaignService.discountPercent / 100));
      if (range[1]) {
        offer[1] = parseFloat(range[1]) * (1 - parseFloat(campaignService.discountPercent / 100));
      }
    }
  }

  if (offer[0] < 0) {
    offer[0] = 0;
  }

  if (offer[1] && offer[1] < offer[0]) {
    return '';
  }

  return offer.map((item) => formatPrice(item)).join('-');
}

/**
 * Find the coresponding campaign for the service if any
 * Returns the campaign and the campaign service
 * @param  {Service} service
 * @param  {Array} campaigns
 * @param  {Array} allServicesIds -> array of ids of all the services selected
 */
export function getServiceCampaignIfAny(service, campaigns, allServicesIds = []) {
  /* we assume the campaign is active for the date selected /
   the check was made before sending the campaign to compute prices */
  let result = {};

  if (campaigns && campaigns.length > 0) {
    for (let i = 0; i < campaigns.length; i++) {
      const campaign = campaigns[i];
      const { type, discountPercent, services } = campaign || {};

      // is Sistaminuten
      if (type === -1) {
        result = { campaign, campaignService: { discountPercent } };
        break;
      }

      const servicesIds = type === 6 ? [service.id] : allServicesIds;
      const campaignServicesIds = services.map((service) => service.id);

      const applyCampaign =
        campaignServicesIds.indexOf(service.id) >= 0 &&
        (type === 6
          ? servicesIds.indexOf(service.id) >= 0
          : campaignServicesIds.every((id) => servicesIds.indexOf(id) >= 0));
      if (applyCampaign) {
        for (let j = 0; j < campaignServicesIds.length; j++) {
          const campaignService = services[j];

          if (service.id === campaignService.id) {
            result = { campaign, campaignService };
            break;
          }
        }

        break;
      }
    }
  }

  return result;
}

export function getCampaignOfferTotal(offerTotal, initialTotal) {
  if (offerTotal !== initialTotal) {
    if (offerTotal.indexOf('-') !== -1) {
      offerTotal = offerTotal
        .replace(/(\s)|(kr)/g, '')
        .split('-')
        .every((price) => parseInt(price, 10) === 0)
        ? __('freePrice')
        : [...new Set(offerTotal.split('-').map((v) => parseInt(v, 10)))].length < 2
        ? offerTotal.split('-')[1]
        : offerTotal;
    }
  }
  return offerTotal;
}

export function getTotalPrice(
  services,
  place,
  priceId,
  giftcard,
  offlineFee = 0,
  capacity = 1,
  label = true,
  campaigns = [],
  hasDiscount = false,
  time,
  bookingBundle = null,
) {
  if (!(services && services.length > 0) || !(place && place.about)) {
    return '';
  }

  if (!showPrices(place, services)) {
    return '';
  }

  const invalidReturn = place.source === 2 ? __('variablePrice') : '';

  const length = services.length;
  const servicesIds = services.map((service) => {
    return service.service ? service.service.id : service.id;
  });

  let total = 0;

  for (let i = 0; i < length; i++) {
    const service = services[i].service ? services[i].service : services[i];

    // invalid service
    if (isEmpty(service) || typeof service !== 'object' || !service.priceType || !service.priceType.prices) {
      return invalidReturn;
    }

    const { about } = service;
    let { settings = {} } = about || {};

    // it's a free service
    if (place.source === 2 && settings.isChargeable && settings.isFree) {
      continue;
    }

    let price;
    const hasDynamicPrice = hasDynamicPricing(service);
    const dynamicPriceKey = getDynamicPriceKey(priceId, time?.dynamicPriceListIdKey);

    if (
      priceId &&
      priceId in service.priceType.prices &&
      (!hasDynamicPrice || time?.dynamicPriceListIdKey === bookConstants.DYNAMIC_PRICE_KEY_DEFAULT)
    ) {
      price = Number(service.priceType.prices[priceId]);
      if (isNaN(price) || (price === 0 && place.source === 2)) {
        return invalidReturn;
      }
    } else if (hasDynamicPrice && dynamicPriceKey && dynamicPriceKey in service.priceType.prices) {
      // the time chosen has weekend/evening price
      price = Number(service.priceType.prices[dynamicPriceKey]);
      if (isNaN(price) || (price === 0 && place.source === 2)) {
        return invalidReturn;
      }
    } else if (Object.keys(service.priceType.prices).length > 0) {
      // is a range probably
      const serviceRange = getServiceRangePrice(service.priceType.prices, priceId, hasDynamicPrice ? time : undefined);
      if (isNaN(parseInt(serviceRange, 10))) {
        return invalidReturn;
      }

      price = sumUp(serviceRange, 0);
    } else {
      price = Number(service.price);

      if (isNaN(price) || (price === 0 && place.source === 2)) {
        return invalidReturn;
      }
    }

    // apply campaign if any for the service
    const { campaignService } = getServiceCampaignIfAny(service, campaigns, servicesIds);
    if (campaignService) {
      const offerPrice = getOfferPrice(price, campaignService);
      if (offerPrice) {
        price = offerPrice;
      }
    }

    const hasBundleForService = bookingBundle && service.id === bookingBundle?.service?.serviceId;

    if (!hasBundleForService) {
      total = sumUp(total, price);

      if (hasDiscount) {
        total = sumUp(total, total * -0.2);
      }
    }
  }

  // nothing to pay
  if (Number(total) === 0) {
    return label === false ? 0 : __('freePrice');
  }

  // add capacity
  capacity = parseInt(capacity, 10);
  if (capacity > 1) {
    const totalRange = ('' + total).split('-', 2);
    totalRange[0] = capacity * parseFloat(totalRange[0]);
    if (totalRange[1]) {
      totalRange[1] = capacity * parseFloat(totalRange[1]);
    }

    total = totalRange.join('-');
  }

  const isPriceVariable = shouldShowFrom(services, place);

  // add giftcards
  if (giftcard && Object.keys(giftcard).length > 0) {
    total = parseFloat(total);
    if (!isNaN(total) && total > 0) {
      Object.keys(giftcard).forEach((code) => {
        const giftcardAmount = giftcard[code].amount / 100;
        total = total - giftcardAmount;
      });

      if (total <= 0) {
        if (isPriceVariable) {
          return invalidReturn;
        } else {
          return label === false ? 0 : __('freePrice');
        }
      }
    }
  }

  // add offline fee
  const fee = Number(offlineFee);
  if (!isNaN(fee) && fee > 0) {
    total = sumUp(total, capacity * fee);
  }

  if (('' + total).indexOf('NaN') !== -1) {
    return invalidReturn;
  }

  if (label === false) {
    // price unformatted
    const range = ('' + total).split('-');

    // if it's a from price do not return a range
    if (isPriceVariable) {
      return range[0];
    }

    return total;
  } else {
    // price formatted
    const range = ('' + total).split('-').map((item) => formatPrice(item));

    // if it's a from price do not return a range
    if (isPriceVariable) {
      return range[0] + ' kr';
    }

    return range.join('-') + ' kr';
  }
}

export function shouldPickEmployee(props, serviceId) {
  const { source, place, append, service } = props;

  if (!isMobile()) {
    return false;
  }

  if (append) {
    return false;
  }

  if (source !== 'company_details') {
    return false;
  }

  // Has hidden employees
  const hideEmployees =
    place && place.about && place.about.settings && place.about.settings.hideEmployees ? true : false;
  if (hideEmployees) {
    return false;
  }

  const selectedServiceId = serviceId ? serviceId : service && service.id;
  if (!selectedServiceId) {
    return false;
  }

  if (getEmployeesWhoPerformServices(place.employees, [selectedServiceId]).length < 2) {
    return false;
  }

  return true;
}

export function getEmployeesWhoPerformServices(employees, serviceIds) {
  // Filter by services
  const employeesData = employees.filter((empl) => {
    if (!empl.about || !empl.about.isBookable || empl.about.inactive) {
      return false;
    }

    let foundAll = 0;
    for (let i = 0; i < empl.services.length; i++) {
      if (serviceIds.indexOf(parseInt(empl.services[i], 10)) > -1) {
        foundAll++;
      }
    }

    if (foundAll === serviceIds.length) {
      return true;
    }

    return false;
  });

  return employeesData;
}

export function activeUsersCount(employees, props) {
  let employeeCount = 0;
  const users = [];
  const { availability } = props;

  (employees || []).forEach((employee, i) => {
    if (!availability || !availability.fromErpOverview || !availability.fromErpOverview.employees[employee.id]) {
      return null;
    }
    users.push(employee.id);
    employeeCount++;
  });

  return { employeeCount, users };
}

export function mergeTitles(services) {
  const result = services
    .map((item) => {
      if (item.service) {
        return capitalizeFirstLetter(item.service.name || '');
      } else {
        return capitalizeFirstLetter(item.name || '');
      }
    })
    .filter((a) => a);

  return result.join(', ');
}

export function isTotalPayable(sum, showPrices = false, isFromPrice = false) {
  const total = Number(sum);
  return showPrices && !isFromPrice && !isNaN(total) && total > 0;
}

export function showPrices(place, services) {
  services = services && services.length ? services : [services];

  for (let i = 0; i < services.length; i++) {
    const service = services[i] && services[i].service ? services[i].service : services[i];

    if (service && service.about && service.about.settings && service.about.settings.hidePrice) {
      return false;
    }
  }

  if (place && place.about && place.about.settings && place.about.settings.showPrices === false) {
    return false;
  }

  return true;
}

// Add two prices
function sumUp(a, b) {
  const range1 = ('' + a).replace('kr', '').replace(/\s/g, '').split('-', 2);
  const range2 = ('' + b).replace('kr', '').replace(/\s/g, '').split('-', 2);
  const totalRange = [];

  totalRange[0] = parseFloat(range1[0]) + parseFloat(range2[0]);

  if (range1[1] && range2[1]) {
    totalRange[1] = parseFloat(range1[1]) + parseFloat(range2[1]);
  } else {
    if (range2[1]) {
      totalRange[1] = parseFloat(range1[0]) + parseFloat(range2[1]);
    } else {
      if (range1[1]) {
        totalRange[1] = parseFloat(range1[1]) + parseFloat(range2[0]);
      }
    }
  }

  const total = totalRange.join('-');
  return total;
}

export function getStartTime(time) {
  return parseInt(time.timestamp, 10) + parseInt(time.selected, 10) * 1000;
}

export function findEmployee(id, list) {
  let selectedEmployee = false;
  list.forEach((employee) => {
    if (parseInt(employee.id, 10) === parseInt(id, 10)) {
      selectedEmployee = employee;
    }
  });
  return selectedEmployee;
}

/**
 * Returns place timezone (or default)
 */
export function getPlaceTimezone(place) {
  // return !isIE ? (place && place.contact && place.contact.timezone) ? place.contact.timezone : "Europe/Stockholm": 'UTC';
  return (place && place.contact && place.contact.timezone) || config.timezone || 'Europe/Stockholm';
}

export function hasFeatureSetting(place, featureSettingId) {
  if (
    place &&
    place.featureSettings &&
    place.featureSettings.indexOf &&
    place.featureSettings.indexOf(featureSettingId) !== -1
  ) {
    return true;
  }
  return false;
}

export function getPayAtSalonLabel(place) {
  const isRenamed = hasFeatureSetting(place, 'renamed_pay_at_salon');
  return !isRenamed ? __('payAtTheSalon') : __('payAtSalonRenamed');
}

/**
 * Returns true if it's ok to display reviews for the given place.
 *
 * This function checks `place.about.settings.displayReviews` configuration option, which should also
 * include logic for employee `displayReviews` option (hide place reviews if all of the
 * place employees turned `displayReview` option off).
 *
 * @returns {boolean}
 */
export function shouldDisplayReviews(place) {
  return !!place.about && !!place.about.settings && !!place.about.settings.displayReviews;
}

/**
 * Returns true if at least one employee is available to do the service
 * @return {Boolean}
 */
const isAtLeastOneActiveEmployee = (service, place = {}) => {
  // We assume this service is active
  const employees = place.employees || [];
  const currentTime = addTimezone(parseInt(new Date().getTime() / 1000, 10)) * 1000;
  for (let i = 0; i < employees.length; i++) {
    const employee = employees[i];
    if (employee && employee.id) {
      const { settings = {}, isBookable } = employee.about;

      const isEmployeeBookable =
        isBookable &&
        !(settings.lastDay && settings.lastDay < currentTime) &&
        employee.services.indexOf(service.id) !== -1;

      if (isEmployeeBookable) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Returns true is the service can be booked
 * @return {Boolean}
 */
export function isServiceBookable(service, place) {
  // is service active
  if (!service || service.duration === 0 || (service.extra && service.extra.inactive === true)) {
    return false;
  }

  // is an active employee doing the service
  return isAtLeastOneActiveEmployee(service, place);
}

export function shouldRequireLogin(place) {
  if (isSistaminuten()) {
    return false;
  }

  return place && place.about && place.about.settings && place.about.settings.requireLogin;
}

export function canPayQliro(employee, time, shouldShowPrices, strict = true) {
  const hasQliro = Boolean(employee && employee.about && employee.about.settings && employee.about.settings.useQliro);
  const timeCondition = time && time.timestamp && time.timestamp < new Date().getTime() + 90 * 24 * 3600 * 1000;

  if (shouldShowPrices && hasQliro) {
    if (strict) {
      return timeCondition;
    } else {
      return true;
    }
  }

  return false;
}

export function canPayKlarna(employee, time, shouldShowPrices, strict = true) {
  const hasKlarna = Boolean(employee && employee.about && employee.about.settings && employee.about.settings.hasKlarna);
  const timeCondition = time && time.timestamp && time.timestamp < new Date().getTime() + 26 * 24 * 3600 * 1000;

  if (shouldShowPrices && hasKlarna) {
    if (strict) {
      return timeCondition;
    } else {
      return true;
    }
  }
  return false;
}

export function canPayCardOnFile(employee, time, shouldShowPrices, total, requirePayNow) {
  const useCardOnFile = employee?.about?.settings?.useCardOnFile;
  // TODO: remove this after all places have migrated to useCardOnFile
  const useCardOnline = employee?.about?.settings?.useCardOnline;
  const useCardOnlinePayLater = employee?.about?.settings?.useCardOnlinePayLater;

  const hasCardOnFile =
    typeof useCardOnFile !== 'undefined' ? !!useCardOnFile : !!(useCardOnline || useCardOnlinePayLater);

  const timeCondition = time && time.timestamp && time.timestamp < new Date().getTime() + 90 * 24 * 3600 * 1000;
  const isPayable = isTotalPayable(total, shouldShowPrices);

  if (hasCardOnFile && isPayable && shouldShowPrices) {
    return requirePayNow || timeCondition;
  }
  return false;
}

export function canPaySwish(employee, time, shouldShowPrices, total) {
  const useSwish = employee?.about?.settings?.useSwishOnline;
  const isPayable = isTotalPayable(total, shouldShowPrices);
  return useSwish && isPayable && shouldShowPrices;
}

export function canPayCardOnFileAfterBooking(booking) {
  if (
    booking.place.source === 2 &&
    booking.status === 1 &&
    !booking.extra.adyenMerchantReference &&
    booking.start * 1000 >= new Date().getTime()
  ) {
    const { place, services } = booking;
    const { employee } = services[0];
    const { canPayOnline, shouldShowPrices } = availableToPayOnline(booking);
    const requirePayNow = isOnlinePaymentRequired(employee, place, services);

    return (
      canPayOnline &&
      canPayCardOnFile(employee, { timestamp: booking.start * 1000 }, shouldShowPrices, booking.price, requirePayNow)
    );
  }
}

export function canPaySwishAfterBooking(booking) {
  if (booking.place.source === 2 && booking.status === 1 && booking.start * 1000 >= new Date().getTime()) {
    const { services } = booking;
    const { employee } = services[0];
    const { canPayOnline, shouldShowPrices } = availableToPayOnline(booking);

    return canPayOnline && canPaySwish(employee, { timestamp: booking.start * 1000 }, shouldShowPrices, booking.price);
  }
}

export function canPayQliroAfterBooking(booking) {
  if (
    booking.place.source === 2 &&
    booking.status === 1 &&
    (!booking.extra.qliroOrderStatus || booking.extra.qliroOrderStatus === 'Refused') &&
    booking.start * 1000 >= new Date().getTime()
  ) {
    const { canPayOnline, shouldShowPrices } = availableToPayOnline(booking);
    return (
      canPayOnline && canPayQliro(booking.services[0].employee, { timestamp: booking.start * 1000 }, shouldShowPrices)
    );
  }
}

function getPriceListFromBooking(booking) {
  const anyEmployee = booking && booking.extra && booking.extra.anyEmployee;
  return (
    (booking &&
      !anyEmployee &&
      booking.services &&
      booking.services[0] &&
      booking.services[0].employee &&
      booking.services[0].employee.about &&
      booking.services[0].employee.about.priceListId) ||
    0
  );
}
function getBookingServices(booking) {
  const services = [];
  booking.services.forEach((booking) => services.push(booking.service));
  return services;
}

function availableToPayOnline(booking) {
  const priceListId = getPriceListFromBooking(booking);
  const services = getBookingServices(booking);
  const shouldShowPrices = showPrices(booking.place, services);
  const totalWithoutFee = getTotalPrice(
    services,
    booking.place,
    priceListId,
    booking.extra.giftcards || {},
    0,
    booking.extra.capacity,
    false,
    [],
    false,
    getDynamicPriceListIdKey(booking),
    booking.extra.appliedBundle,
  );
  return { canPayOnline: isTotalPayable(totalWithoutFee, shouldShowPrices), shouldShowPrices };
}

export function canPayKlarnaAfterBooking(booking) {
  const anyEmployee = booking && booking.extra && booking.extra.anyEmployee;
  const priceListId =
    (booking &&
      !anyEmployee &&
      booking.services &&
      booking.services[0] &&
      booking.services[0].employee &&
      booking.services[0].employee.about &&
      booking.services[0].employee.about.priceListId) ||
    0;

  const services = [];
  booking.services.forEach((booking) => services.push(booking.service));

  let canPayWithKlarna = false;

  // active & is not payed & is not passed
  if (booking.status === 1 && !booking.extra.klarnaOrderId && booking.start * 1000 >= new Date().getTime()) {
    const shouldShowPrices = showPrices(booking.place, services);
    const totalWithoutFee = getTotalPrice(
      services,
      booking.place,
      priceListId,
      booking.extra.giftcards || {},
      0,
      booking.extra.capacity,
      false,
      [],
      false,
      getDynamicPriceListIdKey(booking),
      booking.extra.appliedBundle,
    );
    const canPayOnline = isTotalPayable(totalWithoutFee, shouldShowPrices);
    // Price is payable & employee has klarna & less then 26 days till booking
    canPayWithKlarna =
      canPayOnline && canPayKlarna(booking.services[0].employee, { timestamp: booking.start * 1000 }, shouldShowPrices);
  }

  return canPayWithKlarna;
}

export function getCompanyType(place = {}) {
  return place.source === 1 ? 'bokadirekt' : place.source === 2 ? 'itsperfect' : undefined;
}

export function getBookingTitle(services) {
  const groups = {};
  let isGroupBooking = false;
  services.forEach((booking) => {
    if (booking.service && !groups[booking.service.id]) {
      groups[booking.service.id] = 1;
    } else if (booking.service && groups[booking.service.id] && booking.service.about.capacity > 1) {
      groups[booking.service.id]++;
      isGroupBooking = true;
    }
  });
  if (isGroupBooking) {
    return groups[services[0].service.id] + ' x ' + services[0].service.name;
  }

  return mergeTitles(services);
}

export function isGroupBooking(appointment, withPersonInfo = false, withTitle = false) {
  const personsInfo = [];
  const groups = {};
  let groupBooking = 0;

  appointment.services.forEach((appointmentData) => {
    const { service, cancellationCode } = appointmentData || {};
    if (service.id && groups[service.id] && service.about.capacity > 1) {
      groups[service.id]++;
      groupBooking = groups[service.id];
    } else {
      groups[service.id] = 1;
    }

    if (withPersonInfo) {
      const { formfields = {} } = appointmentData.extra || {};
      if (service.about && service.about.formFields) {
        service.about.formFields.forEach((field) => {
          if (formfields[field.id] && field.label) {
            formfields[field.label] = formfields[field.id];
            delete formfields[field.id];
          }
        });
      }

      personsInfo.push({
        ...formfields,
        cancellationCode,
      });
    }
  });

  let result = {
    groupBooking,
  };

  if (withPersonInfo) {
    result.personsInfo = personsInfo;
  }

  if (withTitle) {
    if (groupBooking) {
      result.bookingTitle = groupBooking + ' x ' + capitalizeFirstLetter(appointment.services[0].service.name);
    } else {
      result.bookingTitle = mergeTitles(appointment.services);
    }
  }

  return result;
}

export async function findPlace(id, slug) {
  const uriSlug = encodeURIComponent(slug);
  return await server.request.get(`/findPlace/${id}/${uriSlug}?slug=${uriSlug}`);
}

export function hasDynamicPricing(service) {
  const { useDynamicPricing } = service?.about?.settings ?? {};
  return !!useDynamicPricing;
}

export function getDynamicPriceListIdKey(appointment) {
  return {
    dynamicPriceListIdKey: appointment?.extra?.dynamicPriceListIdKey || bookConstants.DYNAMIC_PRICE_KEY_DEFAULT,
  };
}

/**
 * Returns a string key used for dynamic prices
 * @param priceListId - price id for an employee
 * @param type 'evening' | 'weekend' | 'none' | undefined
 */
export function getDynamicPriceKey(priceListId, type) {
  if (priceListId && type && type !== bookConstants.DYNAMIC_PRICE_KEY_DEFAULT) {
    return `${priceListId}-${type}`;
  }

  return undefined;
}

export function getPlacePreviewImage(place) {
  const placeImage = place.about?.profileImage || place.about?.images?.length > 0 ? place.about.images[0] : null;

  return placeImage ? getImagePreview(placeImage, '60x60', place.about.slug) : null;
}

/**
 * Status if a place is today open or not
 * @typedef {Object} PlaceStatus
 * @property {"open"|"closed"} status - Is the place open or not
 * @property {string} openHours - The open hours on the current day
 */

/**
 * Check if place is open or not today
 *
 * @param {any} place
 * @returns {PlaceStatus}
 */
export function isPlaceOpenToday(place) {
  const programs = place?.program;
  if (isEmpty(programs) || typeof programs !== 'object') {
    return null;
  }

  let currentDay = moment.tz(getPlaceTimezone(place)).isoWeekday();
  if (currentDay === 7) currentDay = 0;
  const program = programs[currentDay];

  if (program?.length) {
    let timeTo = 0; // Start of day
    let timeFrom = 60 * 60 * 24; // End of day
    program.forEach((timeslot) => {
      if (timeslot?.from < timeFrom) {
        timeFrom = timeslot.from;
      }
      if (timeslot?.to > timeTo) {
        timeTo = timeslot.to;
      }
    });
    const validHours = timeFrom >= 0 && timeTo > timeFrom;
    const openHours = secondsToHour(timeFrom) + ' - ' + secondsToHour(timeTo);

    return validHours ? { status: 'open', openHours: openHours } : { status: 'closed', openHours: '' };
  } else {
    const validHours = program?.from >= 0 && program?.to > program?.from;
    return validHours
      ? { status: 'open', openHours: secondsToHour(program.from) + ' - ' + secondsToHour(program.to) }
      : { status: 'closed', openHours: '' };
  }
}

/**
 * Check if place coordinates is within search area bounds on map
 * @param {{lat: number, lon: number}} point
 * @param {string} _bounds
 * @returns {boolean} - if given place is within bounds
 */
export function isPlaceInBounds(point, _bounds) {
  let bounds = undefined;
  try {
    bounds = JSON.parse(_bounds);
  } catch (e) {}
  if (!(bounds && bounds.hasOwnProperty('_southWest'))) return true;

  if (!point?.lat) return false;

  let eastBound = point.lon < bounds._northEast.lng;
  let westBound = point.lon > bounds._southWest.lng;
  let inLong;

  if (bounds._northEast.lng < bounds._southWest.lng) {
    inLong = eastBound || westBound;
  } else {
    inLong = eastBound && westBound;
  }

  let inLat = point.lat > bounds._southWest.lat && point.lat < bounds._northEast.lat;
  return inLat && inLong;
}

export function getBundleValidity(validMonths) {
  let validity = _s('months', { count: validMonths });
  return _s('buyBundles.valid', { validity: validity });
}
