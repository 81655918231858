/* eslint jsx-a11y/anchor-is-valid: 0 */
import { deployActions, extendedSearchActions, modalActions, placeActions, searchActions } from '@/actions';
import Card from '@/components/elements/Card/Card';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import BannerSlot from '@/components/modules/banner/BannerSlot';
import Breadcrumbs from '@/components/modules/breadcrumbs/Breadcrumbs';
import { Map } from '@/components/modules/map';
import { AndroidTopBanner, IPhoneTopBanner } from '@/components/modules/mobile';
import UserLocationOnboarding, { getIsUserLocationOnboarded } from '@/components/modules/modals/UserLocationOnboarding';
import BottomNavBar from '@/components/modules/navigation/BottomNavBar/BottomNavBar';
import FooterNavigation from '@/components/modules/navigation/FooterNavigation';
import FooterNavigationContainer from '@/components/modules/navigation/FooterNavigationContainer';
import NavBar from '@/components/modules/navigation/navbar/NavBar/NavBar';
import { Filters, SERPMerchantBanner, SERPTopInfoBanner, Search } from '@/components/modules/pages/serp';
import Pagination, { hasNextPage } from '@/components/modules/pages/serp/Pagination';
import SearchInfoWithPopup from '@/components/modules/pages/serp/SearchInfoWithPopup';
import { PlaceCardContainer } from '@/components/modules/place';
import { FakeSearch } from '@/components/modules/search';
import SearchFunctions from '@/components/modules/search/Functions';
import { BANNER_SLOT_LOCATION } from '@/constants/banner';
import {
  classnames,
  filterEmptyValues,
  getCategoryDescription,
  getSERPBreadcrumbs,
  getTrackingPlace,
  getTrackingSearch,
  getTrackingSearchFilters,
  isServer,
  isSistaminuten,
  jsUcfirst,
  server,
  setBookingStartingPoint,
  showAndroidTopBanner,
  showIphoneTopBanner,
  trackMpEvent,
  trackPage,
  updateDeployTimestamp,
  url,
} from '@/helpers';
import withMobileView from '@/hoc/withMobileView';
import withUserLocationPermission from '@/hoc/withUserLocationPermission';
import { __ } from '@/locale';
import { placeService } from '@/services';
import deepEqual from 'deep-equal';
import 'moment-timezone';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const ITEMS_PER_PAGE = 30;

const defaultExtendState = {
  extendPage: 0,
  extend: false,
  offset: 0,
  extendSearchIteration: 0,
};

class ListPage extends React.Component {
  constructor(props) {
    super(props);
    this.setHover = this.setHover.bind(this);
    this.setHoverOut = this.setHoverOut.bind(this);
    this.renderMobile = this.renderMobile.bind(this);

    // get search state from url
    let search = url.getUrlParameters(props);

    this.state = {
      search,
      fetching: false,
      places: props.places || [],
      topSearch: props.topSearch || [],
      results: props.results || 0,
      version: props.version,
      shouldFetchPlaces: false,
      page: Number(search.page) || 0,
      allSearchItems: props.allSearchItems || [],
      extendPage: Number(search.extendPage) || 0,
      extend: !!search.extend || false,
      offset: Number(search.offset) || 0,
      extendSearchIteration: Number(search.extendSearchIteration) || 0,
      fetchMode: 'next',
    };

    if (!isServer && sessionStorage.getItem('whitelabel')) {
      sessionStorage.removeItem('whitelabel');
    }
  }

  seo() {
    const fullURL = `${url.getBaseUrl()}${encodeURIComponent(
      this.props.search && this.props.search.q ? this.props.search.q : 'vad',
    )}/${encodeURIComponent(this.props.search && this.props.search.location ? this.props.search.location : 'var')}${
      this.props.location.search
    }`;

    const { results = 0, page = 0 } = this.state;
    const { search } = this.props;

    const prev =
      page !== 0 ? (
        <link rel="prev" href={fullURL.replace(`page=${page}`, page === 1 ? '' : `page=${page - 1}`)} />
      ) : null;
    let next = null;
    if (hasNextPage(results, page, ITEMS_PER_PAGE)) {
      let nextLink = null;
      if (page === 0 || fullURL.indexOf('page') === -1) {
        if (fullURL.indexOf('?') === -1) {
          nextLink = fullURL + `?page=${page + 1}`;
        } else {
          nextLink = fullURL.replace(/&$/, '') + `&page=${page + 1}`;
        }
      } else {
        nextLink = fullURL.replace(`page=${page}`, `page=${page + 1}`);
      }
      next = nextLink ? <link rel="next" href={nextLink} /> : null;
    }
    const keyword = url.decodeURIComponentSafe(search.q);
    const location = url.decodeURIComponentSafe(search.location);

    const customDescription = getCategoryDescription(keyword, location);
    const generalDescription =
      this.makeTitle() + __(isSistaminuten() ? 'seo.sistaminuten.serpDescription' : 'seo.serpDescription');

    const title =
      (isSistaminuten() ? 'Billigt - Upp till 50% rabatt - ' : '') +
      (page > 0 ? __('seo.page', { page }) : '') +
      this.makeTitle() +
      (isSistaminuten() ? ' - sistaminutentider.se' : ' - Bokadirekt');
    const description =
      (page > 0
        ? __('seo.from-to', {
            from: page * ITEMS_PER_PAGE + 1,
            to: page * ITEMS_PER_PAGE + ITEMS_PER_PAGE > results ? results : page * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
            of: results,
          })
        : '') + (customDescription ? customDescription : generalDescription);
    const keywords = '';
    const imageUrl = url.getBaseImageUrl();
    return (
      <Helmet encodeSpecialCharacters={true}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={fullURL} />
        {prev}
        {next}
        <meta property="og:url" content={fullURL} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:image" content={imageUrl} />
        {!isSistaminuten() && <meta property="og:site_name" content="Bokadirekt" />}
        {!isSistaminuten() && <meta name="twitter:card" content="summary" />}
        {!isSistaminuten() && <meta name="twitter:site" content="@bokadirekt" />}
      </Helmet>
    );
  }

  componentDidMount() {
    localStorage.removeItem('isLanding');
    localStorage.removeItem('slug');

    trackPage();

    if (!isServer) {
      window.scrollTo(0, 0);
    }

    if (this.state.places.length === 0 || this.props.search.shouldUpdate) {
      this.getResults(true);
    }
  }

  checkIsParamsChanged(prevSearchProps, searchProps) {
    if (!searchProps.shouldUpdate) return false;
    const searchKeys = [
      'q',
      'location',
      'locationId',
      'page',
      'shouldUpdate',
      'position',
      'startDate',
      'endDate',
      'sort',
      'timeOfDay',
      'prefs',
      'extendPage',
      'extendSearchIteration',
      'extend',
      'bounds',
      'explain',
      'version',
      'bundles',
      'startingScoreVersion',
    ];

    const oldProps = searchKeys.reduce(
      (acc, key) => (prevSearchProps.hasOwnProperty(key) && (acc[key] = prevSearchProps[key]), acc),
      {},
    );
    const newProps = searchKeys.reduce(
      (acc, key) => (searchProps.hasOwnProperty(key) && (acc[key] = searchProps[key]), acc),
      {},
    );

    return url.serialize(filterEmptyValues(oldProps)) !== url.serialize(filterEmptyValues(newProps));
  }

  componentDidUpdate(prevProps) {
    const { fetching, shouldFetchPlaces, extend } = this.state;
    const { search } = this.props;
    const { dynamicSearchExtension, dynamicSearchExtensionOffset } = search;

    const isParamsChanged = this.checkIsParamsChanged(prevProps.search, search);

    if (!fetching && (isParamsChanged || shouldFetchPlaces)) {
      if (isParamsChanged) {
        this.setState(
          {
            ...defaultExtendState,
            allSearchItems: [],
            page: 0,
            topSearch: [],
          },
          () => {
            this.getResults(true);
          },
        );
      } else {
        if (extend) {
          this.getExtendedResults();
        } else {
          this.getResults();
        }
      }

      const trackingPrevFilters = getTrackingSearchFilters(prevProps.search);
      const trackingFilters = getTrackingSearchFilters(this.props.search);
      if (trackingFilters.length > 0 && !deepEqual(trackingPrevFilters, trackingFilters, { strict: true })) {
        trackMpEvent('filter_applied', {
          screen_name: this.getScreenName(),
          search_filter: trackingFilters,
        });
      }
      if (!isServer) {
        window.scrollTo(0, 0);
      }
    }
    if (this.props.deploy) {
      updateDeployTimestamp(this.props.deploy);
      this.props.dispatch(deployActions.clearDeployTimestamp());
    }

    if (dynamicSearchExtension && !extend) {
      this.handleDynamicExtendSearch(dynamicSearchExtensionOffset);
    }

    // if (prevProps.listType !== this.props.listType) {
    //   trackMpEvent('screen_shown', {...{'screen_name': this.getScreenName()}, ...getTrackingSearch(this.props.search)});
    // }
  }

  getDefaultSearchParams = () => {
    const params = { ...this.state.search, ...this.props.search };
    if (params.location !== __('currentLocation')) {
      delete params.position;
    }

    if (params.location && params.location === __('currentLocation')) {
      delete params.location;
    }

    const fieldsRequiredOnServer = [
      'q',
      'location',
      'locationId',
      'position',
      'prefs',
      'bounds',
      'orderBy',
      'version',
      'startDate',
      'endDate',
      'timeOfDay',
      'sort',
      'lat',
      'lon',
      'page',
      'extend',
      'extendPage',
      'offset',
      'extendSearchIteration',
      'explain',
      'bundles',
      'startingScoreVersion',
    ];
    // delete extra keys
    Object.keys(params)
      .filter((key) => fieldsRequiredOnServer.indexOf(key) === -1)
      .forEach((key) => {
        delete params[key];
      });

    params.version = this.state.version;

    return filterEmptyValues(params);
  };

  getResults = async (updateTotal = false) => {
    const { dispatch } = this.props;
    const { allSearchItems, page, extend, extendPage, offset, extendSearchIteration, fetchMode = 'next' } = this.state;
    this.setState({ fetching: true });

    dispatch(extendedSearchActions.resetExtendedSearch());
    const searchParams = { ...this.getDefaultSearchParams(), page };
    const response = await server.request
      .get('/search', {
        params: searchParams,
        paramsSerializer: url.serialize,
      })
      .then(server.handleSuccess)
      .catch(server.handleError);

    if (response.version !== this.state.version) {
      this.setState({ version: response.version });
    }

    let totalResults = 0;
    if (response.hits) {
      totalResults = response.hits.value;
    }

    if (isSistaminuten()) {
      delete response.topSearch;
    }

    let biddingPlaces =
      response.topSearch && response.topSearch.places
        ? response.topSearch.places.filter((place) => place.topSearch)
        : [];
    const hasBiddingPlaces = biddingPlaces && biddingPlaces.length;
    if (hasBiddingPlaces) {
      // Call backend to store
      placeService.storeImpressions(biddingPlaces.map((place) => place.topSearch.bidId));
    }

    const updatedAllSearchItems =
      fetchMode === 'prev'
        ? [...(response.places || []), ...allSearchItems]
        : [...allSearchItems, ...(response.places || [])];

    this.setState({
      places: response.places || [],
      mainResults: totalResults,
      ...(updateTotal
        ? {
            results: totalResults,
            topSearch: response.topSearch || [],
          }
        : {}),
      shouldFetchPlaces: false,
      allSearchItems: updatedAllSearchItems,
    });
    this.props.dispatch(
      searchActions.storeList({
        ...response,
        allSearchItems: updatedAllSearchItems,
        page,
        extend,
        extendPage,
        offset,
        extendSearchIteration,
      }),
    );
    this.props.dispatch(searchActions.setParameter({ shouldUpdate: false }));

    if (updateTotal && page === 0) {
      trackMpEvent('screen_shown', {
        ...{ screen_name: this.getScreenName(), has_raketen: hasBiddingPlaces ? 'yes' : 'no' },
        ...getTrackingSearch(this.props.search, totalResults),
      });
    }

    this.setState({ fetching: false }, () => {
      if (!isServer) {
        window.scrollTo(0, 0);
      }
    });
  };

  getExtendedResults = async (updateTotal = false) => {
    const { history } = this.props;
    const { extendPage, allSearchItems, results, places, extendSearchIteration, offset, page } = this.state;

    this.setState({ fetching: true });
    const searchParams = this.getDefaultSearchParams();
    const extendedResponse = await server.request
      .get('/search', {
        params: {
          ...searchParams,
          extend: true,
          extendPage,
          limit: extendPage > 0 ? ITEMS_PER_PAGE : ITEMS_PER_PAGE - places.length,
          offset,
          extendSearchIteration,
        },
        paramsSerializer: url.serialize,
      })
      .then(server.handleSuccess)
      .catch(server.handleError);

    let totalResults = 0;
    if (extendedResponse.hits) {
      totalResults = extendedResponse.hits.value;
    }

    const extendSearchParams = {
      extend: true,
      offset: offset + (extendedResponse.places || []).length,
    };
    this.setState({
      places: [...(extendPage > 0 ? [] : places), ...(extendedResponse.places || [])],
      ...(updateTotal ? { results: results + totalResults } : {}),
      shouldFetchPlaces: false,
      fetching: false,
      allSearchItems: [...allSearchItems, ...(extendedResponse.places || [])],
      ...extendSearchParams,
    });

    history.push({ search: url.addPaginationFiltersWithValue(extendSearchParams) });
    if (updateTotal) {
      trackMpEvent('extend_search_results_loaded', {
        screen_name: 'search_results',
        current_page: page + 1,
        number_of_search_results: totalResults,
        ...getTrackingSearch(this.props.search),
      });
    }

    if (!isServer && extendPage > 0) {
      window.scrollTo(0, 0);
    }
  };

  getScreenName() {
    return this.props.isMobileView && this.props.listType !== 'list' ? 'search_results_map' : 'search_results';
  }

  trackPlace = (place, index, page, hasDiscount) => (e) => {
    let trackingProps = getTrackingPlace(place, this.getScreenName());

    trackingProps['position'] = index;
    const hasDiscount20 =
      !place.topSearch && hasDiscount && place.about && place.about.settings && place.about.settings.topOfSearch;
    trackingProps['has_discount'] = hasDiscount20 ? 'yes' : 'no';
    trackingProps['has_raketen'] = place.topSearch && hasDiscount ? 'yes' : 'no';

    if (hasDiscount20) {
      trackingProps['discount_percentage'] = '20';
    }
    trackMpEvent('company_clicked', { ...trackingProps, ...getTrackingSearch(this.props.search) });

    setBookingStartingPoint('search_results', this.props?.search?.q);
  };

  makeTitle() {
    const { search } = this.props;
    const title = [];
    if (search && search.q) {
      title.push(search.q);
      if (isSistaminuten()) {
        title.push('billigt');
      }
    } else {
      title.push(__('BeautyAndHealth'));
    }

    if (search && search.location) {
      title.push(jsUcfirst(search.location));
    } else {
      title.push(__('allSweden'));
    }

    return jsUcfirst(title.join(' '));
  }

  setHover = (placeId) => {
    const { dispatch } = this.props;
    dispatch(placeActions.setHover(placeId));
  };

  setHoverOut = (placeId) => {
    const { dispatch } = this.props;
    dispatch(placeActions.removeHover());
  };

  getResultsFromCache = (type = 'next') => {
    const { history } = this.props;
    const { allSearchItems, page = 0, extend, extendPage = 0, results = 0 } = this.state;

    let newPage = page;
    let newExtendPage = extendPage;

    if (type === 'next') {
      newPage = +page + 1;
      if (extend) newExtendPage = +extendPage + 1;
    } else {
      newPage = +page > 1 ? +page - 1 : 0;
      if (extend) newExtendPage = +extendPage > 1 ? +extendPage - 1 : 0;
    }

    let places = [];
    if (allSearchItems.length >= (page + 1) * ITEMS_PER_PAGE || allSearchItems.length === results) {
      places = allSearchItems.slice(newPage * ITEMS_PER_PAGE, newPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE);
    }

    const commonNewParams = {
      page: newPage,
      extendPage: newExtendPage,
    };

    if (places && places.length) {
      this.setState({ places, ...commonNewParams }, () => {
        if (!isServer) {
          window.scrollTo(0, 0);
        }
      });
    } else {
      this.setState({
        shouldFetchPlaces: true,
        fetchMode: type,
        ...commonNewParams,
      });
    }

    history.push({ search: url.addPaginationFiltersWithValue(commonNewParams) });
  };

  prevPage = () => {
    const { search } = this.props;
    const { page = 0 } = this.state;

    if (page) {
      trackMpEvent('view_more_results_clicked', {
        ...{ screen_name: 'search_results', trigger_source: 'prev_button' },
        ...getTrackingSearch(search),
      });
      this.getResultsFromCache('prev');
    }
  };

  nextPage = () => {
    const { search } = this.props;

    if (search && hasNextPage(this.state.results, this.state.page || 0, ITEMS_PER_PAGE)) {
      trackMpEvent('view_more_results_clicked', {
        ...{ screen_name: 'search_results', trigger_source: 'next_button' },
        ...getTrackingSearch(search),
      });

      this.getResultsFromCache('next');
    }
  };

  handleExtendSearch = () => {
    const { history } = this.props;
    const { extendSearchIteration } = this.state;
    const extendSearchProps = {
      ...defaultExtendState,
      extendSearchIteration: extendSearchIteration + 1,
    };

    this.setState(extendSearchProps, () => this.getExtendedResults(true));
    history.push({ search: url.addPaginationFiltersWithValue(extendSearchProps) });
  };

  handleDynamicExtendSearch = (offset = 0) => {
    const { history } = this.props;
    const { extendSearchIteration } = this.state;

    const extendSearchProps = {
      extendPage: 0,
      extend: true,
      offset,
      extendSearchIteration: extendSearchIteration || 1,
    };

    this.setState(extendSearchProps);
    history.push({ search: url.addPaginationFiltersWithValue(extendSearchProps) });
  };

  showExtendSearchButton = () => {
    const {
      search: { location: searchLocation, extendSearchDisabled } = {},
      extendedSearch: { extendedPlaces, extendedFetch } = {},
    } = this.props;

    return (
      !extendSearchDisabled &&
      !hasNextPage(this.state.results, this.state.page || 0, ITEMS_PER_PAGE) &&
      searchLocation &&
      !extendedFetch &&
      !extendedPlaces
    );
  };

  renderExtendSearchButton() {
    return (
      <Button onClick={this.handleExtendSearch} style={{ margin: '24px 0' }}>
        {__('extendButton')}
      </Button>
    );
  }

  renderExtendedSearch(isLoading) {
    const { places } = this.state;
    if (!(places && places.length > 0)) return null;
    let title = __('extendSearchTitle');
    return (
      !isLoading &&
      this.showExtendSearchButton() && (
        <div style={{ padding: '16px' }}>
          <div className="col-12" style={{ padding: 0 }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h1 style={{ fontSize: 20, padding: 0 }}>{title}</h1>
              <p style={{ margin: 0 }}>{__('extendSearchSubTitle')}</p>
              {this.renderExtendSearchButton()}
            </div>
          </div>
        </div>
      )
    );
  }

  getInjectionBanner(search) {
    if (!search || !search.q || !search.showInjectionNotice) return null;
    return <SERPTopInfoBanner variant="injectionInfo" important />;
  }

  isMerchant() {
    const { user } = this.props;
    return Boolean(user && user.about && user.about.isMerchant);
  }

  toggleBreadcrumbs = () => {
    this.setState((prevState) => ({ showBreadcrumbs: !prevState.showBreadcrumbs }));
  };

  mobileList() {
    const { places, fetching, topSearch, showBreadcrumbs, page = 0 } = this.state;
    const { search } = this.props;
    const breadcrumbs = getSERPBreadcrumbs(search.q, search.location);
    const heading = this.makeSerpHeading();
    let loader = null;

    if (fetching) {
      loader = (
        <div className="relative mt-20 flex items-center justify-center" style={{ width: '100%', minHeight: '400px' }}>
          <LoadingPlaceHolder />
        </div>
      );
    }

    const topPlacesEntries = !page && topSearch && topSearch.places ? topSearch.places.length : 0;
    const serpPageNo = page + 1;

    const mySource = search && search.prefs && search.prefs.indexOf('5') !== -1 ? 'serp-page-top' : 'serp-page';
    const placeCards = places.map((place, key) => {
      const serpIndex = key + topPlacesEntries;
      return (
        <PlaceCardContainer
          source={mySource}
          place={place}
          key={serpIndex}
          SerpPageNo={serpPageNo}
          SerpIdx={serpIndex}
          onClickPlace={this.trackPlace(place, serpIndex, serpPageNo, false)}
          fireHover={this.setHover}
          fireHoverOut={this.setHoverOut}
          searchParams={this.getSearchParamsForMatchedServices()}
        />
      );
    });

    const items =
      places && places.length ? (
        <div className="mb-4 flex flex-col gap-4">
          {loader}
          {placeCards}
          <ReactTooltip backgroundColor="#2A2E43" effect="solid" />
        </div>
      ) : !fetching ? null : (
        loader
      );

    const topPlaces =
      !page && topSearch && topSearch.places && topSearch.places.length ? (
        <div className="places top-search flex flex-col gap-4">
          {topSearch.places.map((place, key) => {
            const serpIndex = key;
            return (
              <PlaceCardContainer
                source="serp-page-top"
                place={place}
                key={serpIndex}
                SerpPageNo={serpPageNo}
                SerpIdx={serpIndex}
                onClickPlace={this.trackPlace(place, serpIndex, serpPageNo, true)}
                fireHover={this.setHover}
                fireHoverOut={this.setHoverOut}
                searchParams={this.getSearchParamsForMatchedServices()}
              />
            );
          })}
        </div>
      ) : null;

    const prepend = !isSistaminuten() ? (
      showIphoneTopBanner() ? (
        <IPhoneTopBanner source="search_results" />
      ) : showAndroidTopBanner() ? (
        <AndroidTopBanner source="search_results" />
      ) : null
    ) : null;

    return (
      <div className="bg-gradient">
        <NavBar
          hideOnScroll
          source="list"
          navClass="navigation list"
          prepend={prepend}
          defaultNavBarHeight={170}
          headerSlot={
            <div className="px-4 sm:px-10">
              <FakeSearch source="list" />
              <Filters />
            </div>
          }
          titleAction={this.toggleBreadcrumbs}
          floatingSlot={
            <div className={classnames('mt-md container', showBreadcrumbs ? 'block' : 'hidden')}>
              <Card>
                <Breadcrumbs items={breadcrumbs} className="w-full text-sm" />
              </Card>
            </div>
          }
        />
        <div>
          {this.getInjectionBanner(search)}
          {!isSistaminuten() && (
            <BannerSlot
              config={this.props.flags?.[BANNER_SLOT_LOCATION.TOP_BANNER_SEARCH]?.payload}
              locationId={BANNER_SLOT_LOCATION.TOP_BANNER_SEARCH}
            />
          )}
          <div className="!pt-0">
            <div className="container sm:px-10">{!places?.length && !fetching ? this.noMatches() : heading}</div>
            {!isSistaminuten() && places && places.length > 0 && (
              <div className="py-md">
                <BannerSlot
                  config={this.props.flags?.[BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH]?.payload}
                  locationId={BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH}
                />
              </div>
            )}
            <div className="container">
              {this.isMerchant() && <SERPMerchantBanner />}
              {topPlaces}
              {items}
              {this.renderExtendedSearch(fetching)}
              <Pagination
                itemsPerPage={ITEMS_PER_PAGE}
                nextPage={this.nextPage}
                prevPage={this.prevPage}
                results={this.state.results}
                page={this.state.page || 0}
              />
            </div>
          </div>
        </div>
        <FooterNavigationContainer>
          <FooterNavigation />
        </FooterNavigationContainer>
        <BottomNavBar source="list" hideOnScroll />
        {this.seo()}
      </div>
    );
  }

  mobileMap() {
    const { places, topSearch, fetching } = this.state;
    const { extendedSearch: { extendedPlaces, extendedFetch, extendedPage } = {} } = this.props;
    let toSend = [];
    const isLoading = extendedFetch || fetching;

    if (places) {
      toSend = places;
    }

    if (topSearch) {
      toSend.concat(topSearch);
    }

    const placesForMap = [
      ...(toSend && (!extendedPlaces || extendedPage === 0) ? toSend : []),
      ...(extendedPlaces ? extendedPlaces : []),
    ];

    return (
      <div>
        <NavBar
          hideOnScroll
          source="list"
          navClass="navigation map"
          headerSlot={
            <div className="px-4 sm:px-10">
              <FakeSearch source="mapview" />
              <Filters />
            </div>
          }
        />
        <div className="relative">
          <Map places={placesForMap} fetching={isLoading} columnClass="full" />
        </div>
      </div>
    );
  }

  renderMobile() {
    return this.props.listType === 'list' ? this.mobileList() : this.mobileMap();
  }

  makeSerpHeading() {
    const { search } = this.props;
    const { results = 0 } = this.state;
    const keyword = url.decodeURIComponentSafe(search.q);
    const location = url.decodeURIComponentSafe(search.location);
    let description = getCategoryDescription(keyword, location && location.length > 0 ? location : undefined);

    try {
      // remove Lymfmassage when q is massage and giftcard filter is on
      if (
        keyword &&
        keyword === 'massage' &&
        search.prefs &&
        search.prefs === '3' &&
        description &&
        description.indexOf('lymfmassage')
      ) {
        description = description.replace('lymfmassage', '');
      }
    } catch (e) {}

    return (
      <div className="my-2 flex items-start">
        <div className="relative flex w-full flex-col">
          <h1 className="p-0 text-base font-semibold md:text-xl">
            {(keyword || __('BeautyAndHealth')) +
              ' ' +
              (!location ? __('allSweden') : location !== __('currentLocation') ? location : __('fakeSearchNear'))}
            {results > 0 && (
              <span className="relative inline whitespace-nowrap">
                &nbsp;&middot;&nbsp;
                <span className="text-black-600 text-sm font-normal">
                  {results && results > 0 ? results + ' träffar' : ''}
                </span>
              </span>
            )}
          </h1>
          <p className="text-black-900 text-sm">{description}</p>
          <SearchInfoWithPopup />
        </div>
      </div>
    );
  }

  noMatches() {
    const { search } = this.props;
    const keyword = url.decodeURIComponentSafe(search.q);
    const location = url.decodeURIComponentSafe(search.location);
    let searchTitle = keyword ? `“${keyword}` : 'din sökning';
    searchTitle += location ? ` ${__('in')} ${location}${keyword ? '“' : ''}` : '“';

    return (
      <div className={`${this.props.isMobileView ? 'px-4 py-4 sm:px-10' : ''} py-5`}>
        <h1 className="mb-4 text-xl md:text-2xl">{`${__('serpNoMatches.noResultMatches')} ${searchTitle}`}</h1>
        <div className="rounded-lg bg-white p-6">
          <p className="mb-2 text-xl">{__('serpNoMatches.tip.heading')}</p>
          <ul className="list-disc space-y-2 pl-4">
            <li>
              <p>{__('serpNoMatches.tip.tip1')}</p>
            </li>
            <li>
              <p>{__('serpNoMatches.tip.tip2')}</p>
            </li>
            <li>
              <p>{__('serpNoMatches.tip.tip3')}</p>
            </li>
          </ul>
          {this.showExtendSearchButton() && (
            <div className="mt-4">
              <p className="mb-4">{__('serpNoMatches.extendedSearch.description')}</p>
              <Button variant="secondary" onClick={this.handleExtendSearch}>
                {__('serpNoMatches.extendedSearch.cta')}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  getSearchParamsForMatchedServices = () => {
    const params = { ...this.state.search, ...this.props.search };

    const fieldsRequired = ['q', 'startDate', 'endDate', 'timeOfDay', 'sort', 'prefs'];
    // delete extra keys
    Object.keys(params)
      .filter((key) => fieldsRequired.indexOf(key) === -1)
      .forEach((key) => {
        delete params[key];
      });
    return filterEmptyValues(params);
  };

  render() {
    const { places, fetching, topSearch, page = 0 } = this.state;
    const { search } = this.props;
    const heading = this.makeSerpHeading();
    const breadcrumbs = getSERPBreadcrumbs(search.q, search.location);
    let loader = null;

    if (fetching) {
      loader = <LoadingPlaceHolder className="z-[99]" />;
    }

    const topPlacesEntries = !page && topSearch && topSearch.places ? topSearch.places.length : 0;
    const serpPageNo = page ? +page + 1 : 1;
    const mySource = search && search.prefs && search.prefs.indexOf('5') !== -1 ? 'serp-page-top' : 'serp-page';

    const shouldDisplayTooltip =
      !isServer &&
      places &&
      places.some((place) => place.about && place.about.associations && place.about.associations.length > 0);

    if (!isServer) {
      ReactTooltip.rebuild();
    }

    const placesCards = places.map((place, key) => {
      const serpIndex = key + topPlacesEntries;
      return (
        <PlaceCardContainer
          source={mySource}
          place={place}
          key={serpIndex}
          SerpPageNo={serpPageNo}
          SerpIdx={serpIndex}
          onClickPlace={this.trackPlace(place, serpIndex, serpPageNo, false)}
          fireHover={this.setHover}
          fireHoverOut={this.setHoverOut}
          searchParams={this.getSearchParamsForMatchedServices()}
        />
      );
    });

    const items =
      places && places.length ? (
        <div
          className="places mx-auto flex w-full flex-col gap-4 pb-8"
          itemScope
          itemType="http://schema.org/SearchResultsPage">
          {!page && topSearch && topSearch.places && topSearch.places.length
            ? topSearch.places.map((place, key) => {
                const serpIndex = key;
                return (
                  <PlaceCardContainer
                    source="serp-page-top"
                    place={place}
                    key={serpIndex}
                    SerpPageNo={serpPageNo}
                    SerpIdx={serpIndex}
                    onClickPlace={this.trackPlace(place, serpIndex, serpPageNo, true)}
                    fireHover={this.setHover}
                    fireHoverOut={this.setHoverOut}
                    searchParams={this.getSearchParamsForMatchedServices()}
                  />
                );
              })
            : null}
          {placesCards}
          {shouldDisplayTooltip && <ReactTooltip backgroundColor="#2A2E43" effect="solid" />}
        </div>
      ) : null;

    return this.props.isMobileView ? (
      this.renderMobile()
    ) : (
      <div className="bg-gradient">
        <NavBar
          source="list"
          navClass="list-container navigation"
          defaultNavBarHeight={174}
          headerSlot={
            <div className="px-lg">
              <div className="w-full xl:w-7/12">
                <Search source="list" />
              </div>
              {!isSistaminuten() ? <Filters /> : <div className="py-2"></div>}
            </div>
          }
        />

        <div className="">
          <div className="lg:w-7/12">
            <div>
              {this.getInjectionBanner(search)}
              {!isSistaminuten() && (
                <BannerSlot
                  config={this.props.flags?.[BANNER_SLOT_LOCATION.TOP_BANNER_SEARCH]?.payload}
                  locationId={BANNER_SLOT_LOCATION.TOP_BANNER_SEARCH}
                />
              )}
              <div className="container-screen py-2">
                <Breadcrumbs items={breadcrumbs} className="text-sm md:text-base" />
              </div>
            </div>
            <div className="ml-0 !pt-0">
              <div className="container-screen relative">
                {loader}
                {!places?.length && !fetching ? this.noMatches() : heading}
              </div>

              {!isSistaminuten() && (
                <div className="py-lg">
                  <BannerSlot
                    config={this.props.flags?.[BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH_WEB]?.payload}
                    locationId={BANNER_SLOT_LOCATION.MIDDLE_BANNER_SEARCH_WEB}
                    inContainer={false}
                  />
                </div>
              )}
              <div className="container-screen">
                {this.isMerchant() && <SERPMerchantBanner />}
                {!fetching && items}
                {this.renderExtendedSearch(fetching)}
                <div className="pb-8">
                  <Pagination
                    itemsPerPage={ITEMS_PER_PAGE}
                    nextPage={this.nextPage}
                    prevPage={this.prevPage}
                    results={this.state.results}
                    page={this.state.page || 0}
                  />
                </div>
              </div>
              <div className="hidden lg:block">
                <Map places={places} fetching={fetching} />
              </div>
            </div>
          </div>
        </div>
        <FooterNavigationContainer>
          <FooterNavigation />
        </FooterNavigationContainer>
        {this.seo()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { search, extendedSearch, listType, deploy, flags, modal } = state;
  const {
    places,
    topSearch,
    results,
    version,
    page,
    allSearchItems,
    extend,
    extendPage,
    offset,
    extendSearchIteration,
  } = search;
  const { user } = state.users;

  return {
    version,
    places,
    topSearch,
    results,
    search,
    listType,
    extendedSearch,
    deploy,
    user,
    page,
    allSearchItems,
    extend,
    extendPage,
    offset,
    extendSearchIteration,
    flags,
    modal,
  };
}

class ListPageWithLocationPermission extends SearchFunctions {
  componentDidMount() {
    const showOnboardingModal = !getIsUserLocationOnboarded() && !this.state.location;

    if (showOnboardingModal) {
      this.props.dispatch(modalActions.userLocationOnboarding({ show: true }));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(modalActions.userLocationOnboarding({ show: false }));
  }

  render() {
    const { login, userLocationOnboarding } = this.props.modal;
    const showUserLocationOnboarding = !login?.show && userLocationOnboarding?.show;

    return (
      <>
        {showUserLocationOnboarding && (
          <UserLocationOnboarding
            isOpen={showUserLocationOnboarding}
            onClose={() => this.props.dispatch(modalActions.userLocationOnboarding({ show: false }))}
            onRequestUserLocation={() => this.handleRequestUserLocationOnbarding(true)}
          />
        )}
        <ListPage {...this.props} />
      </>
    );
  }
}

export default withUserLocationPermission(
  withMobileView(withRouter(connect(mapStateToProps)(ListPageWithLocationPermission))),
);
