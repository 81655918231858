import { modalActions, searchActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import { classnames, isSistaminuten, removeCookie } from '@/helpers';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import withMobileView from '@/hoc/withMobileView';
import withUserLocationPermission from '@/hoc/withUserLocationPermission';
import { __ } from '@/locale';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserLocationOnboarding from '../modals/UserLocationOnboarding';
import { DateTimeAutosuggest, KeywordAutosuggest, LocationAutosuggest } from './Autosuggest';
import { getDateTimeFilterLabel } from './Autosuggest/DateTime';
import SearchFunctions from './Functions';

class Search extends SearchFunctions {
  componentWillUnmount() {
    const { dispatch, modal } = this.props;
    if (modal?.userLocationOnboarding?.show) {
      dispatch(modalActions.userLocationOnboarding({ show: false }));
    }
  }

  clearLocation = async (e) => {
    const { dispatch } = this.props;
    this.setState({ location: '', position: '', locationId: undefined }, async () => {
      dispatch(searchActions.setParameter({ shouldUpdate: false }));
      await dispatch(searchActions.removeParameter(['location', 'locationId', 'position', 'sort']));
      dispatch(searchActions.stopLocationLoader());
      removeCookie('BokadirektLocationData');
    });
  };

  updateSearch = (value, skipSearch = false) => {
    const { source } = this.props;
    this.setState({ q: value }, () => {
      if (!skipSearch && !this.props.isMobileView) {
        this.props.dispatch(searchActions.setParameter({ q: this.state.q }));
        this.gotoSearchAndChangeSorter(source !== 'list');
      }
    });
  };

  clearSearch = (e) => {
    const { source, dispatch } = this.props;
    this.setState({ q: '' }, () => {
      dispatch(searchActions.removeParameter('q'));
      if (source === 'list') {
        this.gotosearch();
      }
    });
  };

  forceOpenLocation = () => {
    this.props.isMobileView && this.setState({ showLocation: true });
  };
  forceCloseLocation = () => {
    this.props.isMobileView && this.setState({ showLocation: false });
  };

  forceOpenDateTimeFilter = () => {
    this.props.isMobileView && this.setState({ showDateTimeFilter: true });
  };
  forceCloseDateTimeFilter = () => {
    this.props.isMobileView && this.setState({ showDateTimeFilter: false });
  };

  render() {
    const { isMobileView, source, modal, loading: isUserLocationLoading } = this.props;
    const { login, userLocationOnboarding } = modal;
    const { startDate, endDate, timeOfDay } = this.props.search || {};

    const showUserLocationOnboarding = !login?.show && userLocationOnboarding?.show;

    return (
      <>
        {showUserLocationOnboarding && (
          <UserLocationOnboarding
            isOpen={showUserLocationOnboarding}
            onClose={() => this.props.dispatch(modalActions.userLocationOnboarding({ show: false }))}
            onRequestUserLocation={() => this.handleRequestUserLocationOnbarding(!isMobileView)}
          />
        )}
        <form
          autoComplete="off"
          className={classnames(
            `${source}-page align-center flex rounded-lg md:rounded-full`,
            !isMobileView && 'input-group',
            source !== 'home' && 'flex-col md:flex-row',
          )}
          onSubmit={this.onSubmit}>
          <KeywordAutosuggest
            update={this.updateSearch}
            clear={this.clearSearch}
            source={this.props.source}
            locationValue={this.state.location}
            forceOpenLocation={this.forceOpenLocation}
            forceOpenDateTimeFilter={this.forceOpenDateTimeFilter}
            dateTimeValue={getDateTimeFilterLabel(startDate, endDate, timeOfDay)}
            isUserLocationLoading={isUserLocationLoading}
            autoFocus={!showUserLocationOnboarding}
          />

          <LocationAutosuggest
            update={this.updateLocation}
            clear={this.clearLocation}
            source={this.props.source}
            forceOpen={this.state.showLocation}
            forceClose={this.forceCloseLocation}
            isUserLocationLoading={isUserLocationLoading}
          />

          {!isSistaminuten() && (source !== 'home' || this.state.showDateTimeFilter) && (
            <DateTimeAutosuggest forceOpen={this.state.showDateTimeFilter} forceClose={this.forceCloseDateTimeFilter} />
          )}
          {!isMobileView ? (
            <span>
              <Button
                className="-ml-[1px] !rounded-br-full !rounded-tr-full text-base hover:opacity-100"
                style={{ width: source === 'home' ? '140px' : '96px', height: source === 'home' ? '56px' : '48px' }}
                type="submit"
                onMouseDown={this.handleClick('search-button-clicked')}>
                {__('search')}
              </Button>
            </span>
          ) : (
            <Button
              size="lg"
              type="submit"
              className={`mb-4 hover:opacity-100 ${
                source === 'home'
                  ? '!h-[52px] !w-[74px] !rounded-bl-none !rounded-br-full !rounded-tl-none !rounded-tr-full sm:!w-28'
                  : ''
              }`}
              onMouseDown={this.handleClick('search-button-clicked')}>
              {__('search')}
            </Button>
          )}
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { search, modal } = state;
  const { listType } = state.listType;
  return {
    search,
    listType,
    modal,
    flags: state.flags,
  };
}

const SearchForm = withUserLocationPermission(
  withMobileView(withRouter(connect(mapStateToProps)(withAmplitudeExperiment(Search)))),
);
export default SearchForm;
