import { modalActions } from '@/actions';
import { usePinInput } from '@/components/elements/forms/PinInput/PinInput';
import RequestToken from '@/components/templates/login/RequestToken';
import VerifyOTP from '@/components/templates/login/VerifyOTP';
import VerifySuccess from '@/components/templates/login/VerifySuccess';
import { SCREEN_NAME } from '@/constants/analytics';
import { LoginContext, ModalView } from '@/hooks/useLogin';
import useMobileView from '@/hooks/useMobileView';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { LoginTriggerSource } from '@/types/analytics';
import { Dispatch, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Modal, { ModalProps } from './redesign/Modal/Modal';

type LoginModalProps = ModalProps & {
  onLoginSuccess: () => void;
  source: LoginTriggerSource;
};

const baseTranslationKey = (view: ModalView) => `loginModal.${view}`;

const closeLoginModal = (clearState: () => void, dispatch: Dispatch<any>) => {
  dispatch(modalActions.login({ show: false }));
  clearState();
};

const LoginModal = ({ isOpen, source }: LoginModalProps) => {
  const { view } = useContext(LoginContext);
  return (
    <Modal isOpen={isOpen} shouldCloseOnOverlayClick={false}>
      {view === 'requestToken' && <RequestTokenContent source={source} />}
      {view === 'verifyOtp' && <VerifyOTPContent source={source} />}
      {view === 'verifySuccess' && <VerifySuccessContent source={source} />}
    </Modal>
  );
};

const VerifySuccessContent = ({ source }: { source: LoginTriggerSource }) => {
  const { email, view, clearLoginState } = useContext(LoginContext);
  const { isMobileView } = useMobileView();
  const dispatch = useDispatch();

  const handleCloseLoginModal = () => closeLoginModal(clearLoginState, dispatch);
  useTrackScreenView(
    { name: 'screen_view_login_success', properties: { trigger_source: source } },
    [],
    [source !== SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY && source !== SCREEN_NAME.CHECKOUT_BUNDLE_SUMMARY],
  );

  return (
    <Modal.Content floating={!isMobileView} gradientBackground>
      <Modal.Header title={_s(`${baseTranslationKey(view)}.title`)} onBack={handleCloseLoginModal} />
      <div className={`gap-xl pb-lg flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        <VerifySuccess email={email} onAnimationComplete={handleCloseLoginModal} />
      </div>
    </Modal.Content>
  );
};

const VerifyOTPContent = ({ source }: { source: LoginTriggerSource }) => {
  const { email, view, user, updateView, handleLogin, handleEmailResendClicked } = useContext(LoginContext);
  const { isMobileView } = useMobileView();

  const pinProps = usePinInput({ fields: 6, validateCallback: handleLogin });

  useEffect(() => {
    if (user) updateView('verifySuccess');
  }, [user, view]);

  // useTrackScreenView(
  //   {
  //     name: 'screen_view_otp_login_verify',
  //     properties: { trigger_source: source },
  //   },
  //   [],
  // );

  useTrackScreenView(
    { name: 'screen_view_login_fail', properties: { trigger_source: source } },
    [pinProps.error, pinProps.clipError],
    [pinProps.error || pinProps.clipError],
  );

  return (
    <Modal.Content floating={!isMobileView} gradientBackground>
      <Modal.Header title={_s(`${baseTranslationKey(view)}.title`)} onBack={() => updateView('requestToken')} />
      <div className={`gap-xl pb-lg flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        <VerifyOTP {...pinProps} email={email} handleEmailResendClicked={handleEmailResendClicked} source={source} />
      </div>
    </Modal.Content>
  );
};

const RequestTokenContent = ({ source }: { source: LoginTriggerSource }) => {
  const { isMobileView } = useMobileView();
  const loginProps = useContext(LoginContext);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useTrackScreenView({ name: 'screen_view_login_start', properties: { trigger_source: source } });

  return (
    <Modal.Content floating={!isMobileView} gradientBackground>
      <Modal.Header
        title={_s(`${baseTranslationKey(loginProps.view)}.title`)}
        onClose={() => closeLoginModal(loginProps.clearLoginState, dispatch)}
      />
      <div className={`flex flex-col ${isMobileView ? 'px-lg' : ''}`}>
        <RequestToken {...loginProps} source={source} redirectUrl={pathname} />
      </div>
    </Modal.Content>
  );
};

export default LoginModal;
