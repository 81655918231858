import { modalActions } from '@/actions';
import NpsLikelihoodToRecommend from '@/components/templates/nps/NpsLikelihoodToRecommend';
import NpsComment from '@/components/templates/nps/NpsComment';
import { useAppSelector } from '@/hooks';
import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';
import { NpsProvider, NpsContext, NpsModalView } from '@/hooks/useNps';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { Dispatch, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Modal from './redesign/Modal/Modal';
import { ModalContent, ModalDialog } from '@/components/modules/modals';

const baseTranslationKey = (view: NpsModalView) => `npsModal.${view}`;

const closeModal = (clearState: () => void, dispatch: Dispatch<any>) => {
  clearState();
  dispatch(modalActions.nps({ show: false }));
};

const NpsModal = ({ isOpen }) => {
  const { view, clearNpsState } = useContext(NpsContext);
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.users?.user);
  const flags = useAppSelector((state) => state.flags);
  const getVariant = useGetAmplitudeExperimentVariant();
  const location = useLocation();

  if (!user) {
    return null;
  }

  if (!(flags['nps-web']?.value === 'on' || getVariant('nps-web')?.value === 'on')) {
    return null;
  }

  if (
    location.pathname.includes('/places/') ||
    location.pathname.includes('/boka-tjanst/') ||
    location.pathname.includes('checkout')
  ) {
    return null;
  }

  return (
    <ModalDialog
      isOpen={isOpen}
      appElement={document.getElementById('root')}
      showOverlay
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => closeModal(clearNpsState, dispatch)}>
      <ModalContent size="md" stickToBottomRight className="p-lg">
        {view === 'step1' && <NpsLikelihoodToRecommendContent />}
        {view === 'step2' && <NpsCommentContent />}
      </ModalContent>
    </ModalDialog>
  );
};

const NpsLikelihoodToRecommendContent = () => {
  const { view, nps, handleScoreSubmit, clearNpsState } = useContext(NpsContext);
  const dispatch = useDispatch();

  useTrackScreenView({ name: 'screen_view_nps_sheet' });

  return (
    <>
      <Modal.Header
        title={_s(`${baseTranslationKey(view)}.title`)}
        onClose={() => closeModal(clearNpsState, dispatch)}
      />
      <div className={`gap-xl sm:gap-lg flex flex-col`}>
        <NpsLikelihoodToRecommend nps={nps} handleScoreSubmit={handleScoreSubmit} />
      </div>
    </>
  );
};

const NpsCommentContent = () => {
  const npsProps = useContext(NpsContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (npsProps.nps.submitted) {
      closeModal(npsProps.clearNpsState, dispatch);
    }
  }, [npsProps.nps.submitted]);

  return (
    <>
      <Modal.Header
        title={_s(`${baseTranslationKey(npsProps.view)}.title`)}
        onClose={() => closeModal(npsProps.clearNpsState, dispatch)}
      />
      <div className={`gap-xl sm:gap-lg flex flex-col`}>
        <NpsComment {...npsProps} />
      </div>
    </>
  );
};

export default function NpsCard(props) {
  return (
    <NpsProvider>
      <NpsModal {...props} />
    </NpsProvider>
  );
}
